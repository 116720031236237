import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import '../App.css';
import Footer from './footer';
import { Col, Row, Accordion, Form, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsQuestionCircle } from 'react-icons/bs';
import { LuStars } from 'react-icons/lu';


function BusinessDetails({ AccordionOpen, setAccordionOpen,
    user_details, refresh_api, setRefresh_api, setModal_spinner }) {

    const [general_definition, setGeneral_definition] = useState();
    const [business_name, setBusiness_name] = useState();
    const [slogan, setSlogan] = useState();

    const [selectedImage, setSelectedImage] = useState();

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    }

    const [file, setFile] = useState()
    const [description, setDescription] = useState("")

    const [modal_1, setModal_1] = useState(false);
    const modal_1_close = () => setModal_1(false);
    const [modal_2, setModal_2] = useState(false);
    const modal_2_close = () => setModal_2(false);
    const [modal_3, setModal_3] = useState(false);
    const modal_3_close = () => setModal_3(false);
    const [modal_4, setModal_4] = useState(false);
    const modal_4_close = () => setModal_4(false);
    const [modal_5, setModal_5] = useState(false);
    const modal_5_close = () => setModal_5(false);

    const navigate = useNavigate();

    const submit_general_definition = () => {
        setModal_spinner(true);
        axios.post('/api_general_definition', {
            general_definition: general_definition,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const submit_business_name = () => {
        setModal_spinner(true);
        axios.post('/api_business_name', {
            business_name: business_name,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const submit_business_logo = () => {
        setModal_spinner(true);
        const formData = new FormData()
        formData.append("image", file)
        formData.append("description", description)
        axios.post('/api_business_logo', formData, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const submit_slogan = () => {
        setModal_spinner(true);
        axios.post('/api_slogan', {
            slogan: slogan,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    return (
        <div className='BusinessDetails'>
            <div className='title_head'>
                <div className='div_title_head div_color_b'>
                    <div className='title_head_text color_b'>פרטי העסק</div>
                    <div className='title_head_text_2'>הגדרת הבסיס של העסק שלך</div>
                </div>
            </div>
            <div className='aco'>
                <Accordion activeKey={AccordionOpen}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => { AccordionOpen == 0 ? setAccordionOpen(["99"]) : setAccordionOpen(["0"]) }}>תיאור תחום והגדרה כללית</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>המטרה המרכזית בבסיס התהליך, היא למקד אתכם ביתרונות היחסיים שלכם
                                            ולאפשר לכם להתפתח ולהרוויח כסף מתחום ועיסוק שמעניינים אתכם.
                                            בשל כך, הינכם מתבקשים להגדיר הגדרה כללית ראשונית של התחום והפעילות בהם אתם מתמקדים</div>
                                    </Col>
                                    <Col sm={4} className='no_padding'></Col>
                                </Row>
                                <button onClick={() => setModal_1(true)} className='button_question_2'><BsQuestionCircle /> דוגמאות לתיאורים נכונים</button>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>רשמו כאן את התחום וההגדרה הכללית שלכם</div></Form.Label>
                                                <Form.Control type="text" className='form_control_radus' size='sm'
                                                    defaultValue={user_details.general_definition}
                                                    onChange={(e) => setGeneral_definition(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["1"]); submit_general_definition() }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => { AccordionOpen == 1 ? setAccordionOpen(["99"]) : setAccordionOpen(["1"]) }}>שם עסק</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>בבחירת שם העסק עומדות בפניך 3 אופציות</div>
                                        <div>   א. בחירת שם הכולל את שמכם והגדרת העיסוק</div>
                                        <div>  ב. בחירת שם עסק אחר משמכם בצמוד לתיאור תחום העיסוק</div>
                                        <div>  ג. בחירת שם עסק השראתי:</div>
                                        <div>   שם שאינו שמכם ואף לא מכיל את תחום העיסוק,</div>
                                        <div>  אלא שם שמבטא עבורכם ערכים של הצלחה.</div>
                                    </Col>
                                </Row>
                                <button onClick={() => setModal_2(true)} className='button_question_2'><BsQuestionCircle /> דוגמאות לבחירת שם העסק</button>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>שם העסק שבחרתם</div></Form.Label>
                                                <Form.Control type="text" className='form_control_radus' size='sm'
                                                    defaultValue={user_details.business_name}
                                                    onChange={(e) => setBusiness_name(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["2"]); submit_business_name() }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => { AccordionOpen == 2 ? setAccordionOpen(["99"]) : setAccordionOpen(["2"]) }}>לוגו העסק</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={5} className='no_padding'>
                                        <div>בבחירת לוגו לעסק (סמליל) עומדות בפניכם 2 אופציות:</div>
                                        <div> א. תמונה שלכם עם או בלי כיתוב נוסף</div>
                                        <div>  ב. סמל כלשהוא שאתם מתחברים אליו עם כיתוב או בלי</div>
                                        <div className='margin_10'><button onClick={() => setModal_3(true)} className='button_question_logo_1'><BsQuestionCircle /> כיצד לצלם תמונת פרופיל איכותית</button></div>
                                        <div><button onClick={() => setModal_4(true)} className='button_question_logo_2'><BsQuestionCircle /> כיצד לבנות לוגו בכוחות עצמך</button></div>
                                        <div className='logo_model'>להלן רשימת דוגמאות מאפיינות שיסייעו בידך ליצור</div>
                                        <img src={"./all_logo.webp"} alt="logo" width={'90%'} max-height={50} />
                                    </Col>
                                    <Col sm={7} className='no_padding'>
                                        <div className='text_bolt upload_logo'>העלאת הלוגו שלכם</div>
                                        <div>העלאת הלוגו מתאפשרת ע"י צילום עצמי, שימוש במצלמת הטלפון/מחשב, מאגר תמונות חינמי ברשת.</div>
                                        <div>יש לשמור את התמונה/לוגו שיצרתם, בטלפון או במחשב.</div>
                                        <div>יש ללחוץ על הכפתור ולבחור את התמונה ששמרתם.</div>
                                        <div>
                                            <div className='div_input_image'>
                                                <form>
                                                    <input className='input_logo' filename={file} onChange={e => setFile(e.target.files[0])} type="file" accept="image/*"></input>
                                                </form>
                                            </div>
                                            <div className='image_text'>תצוגה מקדימה</div>
                                            {file && (
                                                <div>
                                                    <img src={URL.createObjectURL(file)} alt="logo" className='img_before_upload' />
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["3"]); submit_business_logo() }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="3" className='aco_bottom'>
                        <Accordion.Header onClick={() => { AccordionOpen == 3 ? setAccordionOpen(["99"]) : setAccordionOpen(["3"]) }}>סלוגן</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>מהו סלוגן?</div>
                                        <div>משפט מפתח המגדיר את תחום עיסוקכם והבשורה האישית שאתם מביאים בתחום.</div>
                                    </Col>
                                </Row>
                                <button onClick={() => setModal_5(true)} className='button_question_2'><BsQuestionCircle /> דוגמאות לסלוגנים</button>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>הסלוגן שבחרתם</div></Form.Label>
                                                <Form.Control type="text" className='form_control_radus' size='sm'
                                                    defaultValue={user_details.slogan}
                                                    onChange={(e) => setSlogan(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["4"]); submit_slogan(); navigate("/business_products") }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <Footer />

            <Modal show={modal_1} onHide={modal_1_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>דוגמאות לתיאורים נכונים</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>• אני מורה פרטי עצמאי לאנגלית, הגעתי לתקרת הכנסה ואני מעוניין להרחיב את העסק.</div>
                    <div>• אני מדקר סיני אשר פועל בעיקר מפה לאוזן בבתי לקוחות. הייתי מעוניין לפתוח קליניקה מסודרת ולהרחיב את קהל הלקוחות שלי.</div>
                    <div>• אני שף פרטי המתמחה בהכנת סושי. ברצוני לפתוח סדנאות גורמה של 6-8 אנשים בבית לצד העיסוק שלי כשף פרטי.</div>
                    <div>• אני אשת מכירות מעולה בעלת ניסיון רב. אני פשוט אוהבת למכור! לכן, לצד עבודתי בעירייה, אני רוצה פעמיים בשבוע, אחר   הצהריים, למכור מוצרים או שירותים של חברות, בביתי.</div>
                    <div>• למדתי קוסמטיקה ואיפור כי אני ממש מאוהבת בזה. אני מעוניינת, בנוסף לעבודתי כשכירה, בחצי משרה - לקבל עד 6 לקוחות   קבועות לטיפול בביתי.</div>
                    <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                        <span><Link className='link_marketing' to="/contact_us">
                            קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={modal_2} onHide={modal_2_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>דוגמאות לבחירת שם העסק</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <div className='modal_bolt_1'>  א. בחירת שם הכולל את שמכם והגדרת העיסוק:</div>
                            <div>    למשל: דוד כהן – מורה לנהיגה.</div>
                            <div className='modal_bolt_2'>  ב. בחירת שם עסק אחר משמכם בצמוד לתיאור תחום העיסוק:</div>
                            <div>  • אר ג'י שירותי ניהול מחשבים</div>
                            <div>   • ראשי תיבות אנגלית</div>
                            <div>  • ד"ר שקשוקה</div>
                            <div>  • פיצה מטר</div>
                            <div> • אופק סוכנויות ביטוח</div>
                            <div>  • עתיד שקט ביטוחים</div>
                            <div> • ראש שקט סוכנויות ביטוח</div>
                            <div>  • קו נקי – עיצוב פנים</div>
                        </Col>
                        <Col sm={6}>
                            <div className='modal_bolt_3'>  ג. בחירת שם עסק השראתי:</div>
                            <div>  שם שאינו שמכם ואף לא מכיל את תחום העיסוק,</div>
                            <div>   אלא שם שמבטא עבורכם ערכים של הצלחה.</div>
                            <div>  • וונדרס</div>
                            <div> • וול ביינג</div>
                            <div> • אל על</div>
                            <div> • ארקיע</div>
                            <div> • ישרוטל</div>
                            <div> • קראון פלאזה</div>
                            <div> • אוסם</div>
                            <div> • גשר</div>
                            <div> • תכלית</div>
                            <div> • מיקוד</div>
                            <div> • עננים</div>
                            <div> • אפל</div>
                            <div> • מנפאוור</div>
                            <div> • אקסלנס בית השקעות</div>
                            <div> • קוקה קולה</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_3} onHide={modal_3_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>כיצד לצלם תמונת פרופיל איכותית</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <iframe src="https://player.vimeo.com/video/414423334" width="100%"
                                data-ready="true" allowfullscreen allow="autoplay; fullscreen" height="300"
                            ></iframe>
                        </Col>
                        <Col sm={6}>
                            <div>לנוחותך יצרנו סרטון שמראה שלב אחר שלב</div>
                            <div>איך ניתן לצלם תמונת פרופיל איכותית מבלי להתאמץ.</div>
                            <div>לחלופין, ניתן גם לפנות לגורם מקצועי שידע לסייע לך בזה.</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_4} onHide={modal_4_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>כיצד לבנות לוגו בכוחות עצמך</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <iframe src="https://player.vimeo.com/video/422499464" width="100%"
                                data-ready="true" allowfullscreen allow="autoplay; fullscreen" className='frame_height'
                            ></iframe>
                        </Col>
                        <Col sm={6}>
                            <div>לנוחותך יצרנו עבורך סרטון שמראה שלב אחר שלב</div>
                            <div>איך ניתן ליצור לוגו באמצעות תוכנות ושירותים חינמיים בלי להתאמץ.</div>
                            <div>לחלופין ניתן לפנות לגורם מקצועי שידע לייצר את הלוגו עבורך.</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_5} onHide={modal_5_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>דוגמאות לסלוגנים</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div> • קוקה קולה, טעם החיים</div>
                    <div> • נוקיה – קונקטינג פיפל (מחברים אנשים)</div>
                    <div> • להיות ראשון זה מחייב – בנק הפועלים</div>
                    <div> • שלמה סיקסט – תמיד כן</div>
                    <div> • פולקסווגן כי חיים פעם אחת</div>
                    <div> • מיצובישי ויש בה הכל</div>
                    <div> • יש מכבי יש חברים</div>
                    <div> • קח פרי ותהיה לי בריא</div>
                    <div> • תפוצ'יפס טבעי שלא תשלוט בעצמך</div>
                    <div> • מנו מובילים אותך בדרך הבטוחה</div>
                    <div> • תות – לכל חלום יש תכנית עבודה</div>
                    <div> • גשר-טיולי נכים</div>
                    <div> • ייעוץ עסקי לעסקים שרואים רחוק</div>
                    <div> • מקורית – עיצוב אירועים עד הפרחים הקטנים</div>
                    <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                        <span><Link className='link_marketing' to="/contact_us">
                            קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                    </div>
                </Modal.Body>
            </Modal>

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </div >);
}

export default BusinessDetails;