import React, { useState } from 'react';
import '../App.css';
import axios from 'axios';
import { Col, Row, Form, Modal, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Link } from "react-router-dom";
import { BiSolidLock } from 'react-icons/bi';
import { FaPhoneFlip } from "react-icons/fa6";
import { BsEnvelopeFill } from 'react-icons/bs';
import { FaUser } from 'react-icons/fa';


function Signup({ refresh_api, setRefresh_api, setOnboarding }) {
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [multi_checked, setMulti_checked] = useState(true);
    const [toot_checked, setToot_checked] = useState(true);

    const [modal_1, setModal_1] = useState(false);
    const modal_1_close = () => setModal_1(false);

    const navigate = useNavigate();

    let id_coach = window.location.pathname.split("/").pop()

    const submit = () => {
        if (name && phone && email && password) {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                setModal_1(true);
                axios({
                    method: 'post',
                    url: '/api_signup',
                    data: {
                        name: name,
                        phone: phone,
                        email: email,
                        password: password,
                        multi_checked: multi_checked,
                        toot_checked: toot_checked,
                        coach: id_coach
                    }, withCredentials: true
                })
                    .then((response) => {
                        if (response.status === 200) {
                            modal_1_close();
                            setOnboarding(true);
                            setRefresh_api(refresh_api + 1);
                            navigate("/dashboard");
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            modal_1_close();
                            toast.error("כתובת המייל כבר קיימת במערכת");
                            console.log(error);
                        }
                    })
            }
            else {
                toast.error("כתובת האימייל אינה תקינה");
            }
        }
        else {
            toast.error("יש למלא את כל השדות");
        }
    };

    return (
        <div className='Signup'>
            <Row>
                <Col sm={4}></Col>
                <Col sm={4}>
                    <Form>
                        <div className='signup_tytle'>הרשמה</div>
                        <Form.Group className="mb-3">
                            <Form.Label className='label'>שם מלא</Form.Label>
                            <div className="input-container">
                                <div className='input_icon'>
                                    <FaUser />
                                </div>
                                <input className='input_login' type="text" placeholder="תרשום כאן את השם שלך" onChange={(e) => setName(e.target.value)} />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label'>טלפון</Form.Label>
                            <div className="input-container">
                                <div className='input_icon'>
                                    <FaPhoneFlip />
                                </div>
                                <input className='input_login' type="number" placeholder="הכנס את הטלפון שלך כאן" onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label'>דואר אלקטרוני</Form.Label>
                            <div className="input-container">
                                <div className='input_icon'>
                                    <BsEnvelopeFill />
                                </div>
                                <input className='input_login' type="email" placeholder="הכנס את הדואר אלקטרוני שלך כאן" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label'>סיסמה</Form.Label>
                            <div className="input-container">
                                <div className='input_icon'>
                                    <BiSolidLock />
                                </div>
                                <input className='input_login' type="text" placeholder="תבחר את הסיסמה שלך" onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </Form.Group>
                        <div className='div_lable'><input className='input_c' type='checkbox' label="option 1" id='multi' checked={multi_checked} onChange={() => multi_checked ? setMulti_checked(false) : setMulti_checked(true)} />
                            <lable className='input_c_lable' for='multi'>ברצוני לקבל התראות לגביי מבצעים והטבות של Multi-U</lable></div>
                        <div className='div_lable'><input className='input_c' type='checkbox' label="option 1" id='multi' checked={toot_checked} onChange={() => toot_checked ? setToot_checked(false) : setToot_checked(true)} />
                            <lable className='input_c_lable' for='multi'>ברצוני לקבל התראות לגביי מבצעים והטבות של תות תקשורת ותוצאות</lable></div>
                        <div className='text_private'>מולטי-יו תעבד את הנתונים שלך כדי לשלוח לך מידע על המוצרים והשירותים שלנו,
                            מבצעים, סקרים, הגרלות, בהתבסס על האינטרס הלגיטימי שלנו ועדכונים מהיוצרים שאתה עוקב אחריהם,
                            אם הסכמת לכך. הנתונים שלך לא ייחשפו לצדדים שלישיים.
                            הם יועברו מחוץ לאיחוד האירופי בהתאם לתנאי מדיניות הפרטיות. אתה יכול לבטל את הסכמתך להתראות שלנו עם המחוון הראשון. </div>
                        <div className='d-grid'>
                            <button type='button' className='button_signup' onClick={submit}>הירשם למערכת</button>
                        </div>
                        <div className='div_signup_link'>
                            <div>יש לך כבר משתמש? <Link to="/login" className='signup_link'><span >לחץ כאן</span></Link></div>
                        </div>
                    </Form>
                </Col >
                <Col sm={4}></Col>
            </Row >

            <Modal show={modal_1} onHide={modal_1_close} centered>
                <Modal.Body>
                    <div className='div_spinner'> <Spinner animation="border" variant="warning" /></div>
                </Modal.Body>
            </Modal>

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </div >
    );
}

export default Signup;