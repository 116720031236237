import React, { useState } from 'react';
import '../App.css';
import axios from 'axios';
import { Col, Row, Form, Modal, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Link } from "react-router-dom";
import { BiSolidLock } from 'react-icons/bi';
import { BsEnvelopeFill } from 'react-icons/bs';


function Login({ refresh_api, setRefresh_api }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [modal_1, setModal_1] = useState(false);
    const modal_1_close = () => setModal_1(false);

    const navigate = useNavigate();

    const submit = () => {
        if (email && password) {
            setModal_1(true);
            axios({
                method: 'post',
                url: '/api_login',
                data: {
                    email: email,
                    password: password,
                }, withCredentials: true
            })
                .then((response) => {
                    if (response.status == 200) {
                        setRefresh_api(refresh_api + 1);
                        navigate("/dashboard");
                    }
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        modal_1_close();
                        toast.error("המייל או הסיסמה שגויים");
                        console.log(error);
                    }
                })
        }
        else {
            toast.error("יש למלא את כל השדות");
        }
    };

    return (
        <div className='Login'>
            <Row>
                <Col sm={4}></Col>
                <Col sm={4}>
                    <Form>
                        <div className='login_tytle'>כניסה</div>
                        <Form.Group className="mb-3">
                            <Form.Label className='label'>דואר אלקטרוני</Form.Label>
                            <div className="input-container">
                                <div className='input_icon'>
                                    <BsEnvelopeFill />
                                </div>
                                <input className='input_login' type="email" placeholder="הקלד את הדואר האלקטרוני שלך" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label'>סיסמה</Form.Label>
                            <div className="input-container">
                                <div className='input_icon'>
                                    <BiSolidLock />
                                </div>
                                <input className='input_login_password' type="password" placeholder="הקלד את הסיסמה שלך" onChange={(e) => setPassword(e.target.value)} />
                                <div className='input_password'>
                                    <Link to="/password" className='signup_link'><div className='div_signup_text'>שכחת סיסמה?</div></Link>
                                </div>
                            </div>
                        </Form.Group>
                        <div className='d-grid'>
                            <button type='button' className='button_signup' onClick={submit}>כניסה למערכת</button>
                        </div>
                        <div className='div_signup_link'>
                            <div>אין לך משתמש? <Link to="/signup/1" className='signup_link'><span >לחץ כאן</span></Link></div>
                        </div>
                    </Form>
                </Col>
                <Col sm={4}></Col>
            </Row>

            <Modal show={modal_1} onHide={modal_1_close} centered>
                <Modal.Body>
                    <div className='div_spinner'> <Spinner animation="border" variant="warning" /></div>
                </Modal.Body>
            </Modal>

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </div>
    );
}

export default Login;
