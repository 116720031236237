import React, { useState } from 'react';
import '../App.css';
import axios from 'axios';
import { Col, Row, Container, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { BsEnvelopeFill } from 'react-icons/bs';


function ResetPassword() {
    const [email, setEmail] = useState();

    const navigate = useNavigate();

    const submit = () => {
        if (email) {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                axios({
                    method: 'post',
                    url: '/api_reset_password',
                    data: {
                        email: email,
                    }, withCredentials: true
                })
                    .then((response) => {
                        if (response.status == 200) {
                            Swal.fire({
                                title: "המייל נשלח בהצלחה",
                                text: `שלחנו לך סיסמה חדשה לכתובת המייל ${email}`,
                                icon: 'success',
                                confirmButtonText: 'לכניסה למערכת',
                                confirmButtonColor: '#f3be17'
                            })
                            navigate("/login");
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                            toast.error("כתובת האימייל אינה קיימת במערכת");
                            console.log(error);
                        }
                    })
            }
            else {
                toast.error("כתובת האימייל אינה תקינה");
            }
        }
        else {
            toast.error("יש למלא כתובת אימייל");
        }
    };

    return (
        <div className='ResetPassword'>
            <div className='signup_center'>
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={4}>
                        
                        <Form>
                            <div className='password_tytle'>שכחת את הסיסמה?</div>
                            <Form.Group className="mb-3">
                                <Form.Label className='label'>דואר אלקטרוני</Form.Label>
                                <div className="input-container">
                                    <div className='input_icon'>
                                        <BsEnvelopeFill />
                                    </div>
                                    <input className='input_login' type="email" placeholder="הכנס את הדואר האלקטרוני שלך כאן" onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </Form.Group>
                            <div className='d-grid'>
                                <button type='button' className='button_signup' onClick={submit}>תשלח לי סיסמה חדשה למייל</button>
                            </div>
                            <div className='div_signup_link'>
                                <div>להרשמה למערכת <Link to="/signup/1" className='signup_link'><span >לחץ כאן</span></Link></div>
                            </div>
                        </Form>
                        
                    </Col>
                    <Col sm={4}></Col>
                </Row>
            </div>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </div>
    );
}

export default ResetPassword;