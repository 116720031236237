import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import '../App.css';
import Footer from './footer';
import { Col, Row, Accordion, Form, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsQuestionCircle } from 'react-icons/bs';
import { LuStars } from 'react-icons/lu';


function PropertyMarketings({ AccordionOpen, setAccordionOpen,
    user_details, refresh_api, setRefresh_api, setModal_spinner }) {

    const [facebook, setFacebook] = useState();
    const [instagram, setInstagram] = useState();
    const [minisite, setMinisite] = useState();
    const [youtube, setYoutube] = useState();

    const [modal_1, setModal_1] = useState(false);
    const modal_1_close = () => setModal_1(false);
    const [modal_2, setModal_2] = useState(false);
    const modal_2_close = () => setModal_2(false);
    const [modal_3, setModal_3] = useState(false);
    const modal_3_close = () => setModal_3(false);
    const [modal_4, setModal_4] = useState(false);
    const modal_4_close = () => setModal_4(false);
    const [modal_5, setModal_5] = useState(false);
    const modal_5_close = () => setModal_5(false);

    const navigate = useNavigate();

    const submit_facebook = () => {
        setModal_spinner(true);
        axios.post('/api_facebook', {
            facebook: facebook,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const submit_instagram = () => {
        setModal_spinner(true);
        axios.post('/api_instagram', {
            instagram: instagram,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const submit_minisite = () => {
        setModal_spinner(true);
        axios.post('/api_minisite', {
            minisite: minisite,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const submit_youtube = () => {
        setModal_spinner(true);
        axios.post('/api_youtube', {
            youtube: youtube,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    return (
        <div className='PropertyMarketings'>
            <div className='title_head'>
                <div className='div_title_head div_color_m'>
                    <div className='title_head_text color_m'>נכסים אינטרנטיים</div>
                    <div className='title_head_text_2'>הקמת הנכסים הנחוצים לשיווק העסק</div>
                </div>
            </div>
            <div className='aco'>
                <Accordion activeKey={AccordionOpen}>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header onClick={() => { AccordionOpen == 6 ? setAccordionOpen(["99"]) : setAccordionOpen(["6"]) }}>הקמת דף עסקי בפייסבוק</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>על מנת לייצר דף פייסבוק עסקי איכותי:</div>
                                        <div>א. עלינו לבחור קטגוריה ושם לדף האוהדים.</div>
                                        <div>ב. ישנן שש קטגוריות המגדירות את סוג השירות או המוצר אותו נציע.</div>
                                        <div>ג. בשלב הבא, נוסיף מידע חשוב על פי המסר השיווקי שהכנו.</div>
                                        <div>ד. בנוסף, נעלה תמונת פרופיל איכותית.</div>
                                    </Col>
                                </Row>
                                <div className='margin_10'><button onClick={() => setModal_1(true)} className='button_question_logo_1'>
                                    <BsQuestionCircle /> כיצד לצלם תמונת פרופיל מקצועית</button></div>
                                <div><button onClick={() => setModal_2(true)} className='button_question_logo_2'>
                                    <BsQuestionCircle /> כיצד לפתוח דף עסקי מקצועי בפייסבוק</button></div>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>כעת יש לרשום כאן את כתובת עמוד הפייסבוק שיצרתם</div></Form.Label>
                                                <Form.Control type="text" className='form_control_radus' size='sm'
                                                    defaultValue={user_details.facebook}
                                                    onChange={(e) => setFacebook(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["7"]); submit_facebook() }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                        <Accordion.Header onClick={() => { AccordionOpen == 7 ? setAccordionOpen(["99"]) : setAccordionOpen(["7"]) }}>הקמת פרופיל אינסטגרם עסקי</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>בהקמת פרופיל אינסטגרם חשוב לחברו לדף העסקי בפייסבוק</div>
                                        <div>לצד מילוי הפרטים ההכרחיים יש להוסיף כתובת אי מייל ליצירת קשר, לצד פרטי התקשרות נוספים.</div>
                                        <div>חשוב לדעת כי על מנת לפתוח פרופיל אינסטגרם עסקי,</div>
                                        <div>ראשית יש לפתוח חשבון רגיל ואז להסב אותו.</div>
                                    </Col>
                                </Row>
                                <button onClick={() => setModal_3(true)} className='button_question_2'><BsQuestionCircle /> כיצד לפתוח פרופיל עסקי מקצועי באינסטגרם</button>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>כעת יש לרשום כאן את כתובת פרופיל האינסטגרם שיצרתם</div></Form.Label>
                                                <Form.Control type="text" className='form_control_radus' size='sm'
                                                    defaultValue={user_details.instagram}
                                                    onChange={(e) => setInstagram(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["8"]); submit_instagram() }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                        <Accordion.Header onClick={() => { AccordionOpen == 8 ? setAccordionOpen(["99"]) : setAccordionOpen(["8"]) }}>בניית מיני סייט/דף נחיתה</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>דף נחיתה משמש ככרטיס הביקור המקצועי שלך ברשת.</div>
                                        <div>א. ראשית, נכתוב באתר את המסר השיווקי שהכנו.</div>
                                        <div>ב. נוסיף כתובת אי מייל ופרטי התקשרות</div>
                                        <div>ג. נעלה תמונה/סרטון</div>
                                        <div>ד. נזמין את הגולש להשאיר את פרטיו</div>
                                    </Col>
                                </Row>
                                <button onClick={() => setModal_4(true)} className='button_question_2'><BsQuestionCircle /> כיצד נקים דף נחיתה/מיני סייט מזמין ומקצועי</button>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>כעת יש לרשום כאן את כתובת האתר שיצרתם</div></Form.Label>
                                                <Form.Control type="text" className='form_control_radus' size='sm'
                                                    defaultValue={user_details.minisite}
                                                    onChange={(e) => setMinisite(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["9"]); submit_minisite(); }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9" className='aco_bottom'>
                        <Accordion.Header onClick={() => { AccordionOpen == 9 ? setAccordionOpen(["99"]) : setAccordionOpen(["9"]) }}>צילום סרטון תדמית קצר</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>הסרטון שנצלם ישמש אותנו בכל העמודים שהקמנו: פייסבוק, אינסטגרם, מיני סייט/דף נחיתה.</div>
                                        <div>הסרטון מכיל מסר מדויק וקצר המבוסס על המסר השיווקי שבנינו.</div>
                                        <div>חשוב להתאמן על העברת המסר בצורה ברורה, אנרגטית - לפני הצילום.</div>
                                    </Col>
                                </Row>
                                <button onClick={() => setModal_5(true)} className='button_question_2'><BsQuestionCircle /> כיצד לצלם סרטון תדמית אפקטיבי</button>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>כעת יש להעלות את הסרטון שיצרתם לאתר יוטיוב ולהעתיק את הקישור לכאן</div></Form.Label>
                                                <Form.Control type="text" className='form_control_radus' size='sm'
                                                    defaultValue={user_details.youtube}
                                                    onChange={(e) => setYoutube(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["10"]); submit_youtube(); navigate("/marketing_business") }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <Footer />

            <Modal show={modal_1} onHide={modal_1_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>כיצד לצלם תמונת פרופיל מקצועית</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <iframe src="https://player.vimeo.com/video/417936068" width="100%"
                                data-ready="true" allowfullscreen allow="autoplay; fullscreen" height="300"
                            ></iframe>
                        </Col>
                        <Col sm={6}>
                            <div>לנוחותך יצרנו סרטון שמראה שלב אחר שלב</div>
                            <div>איך ניתן לצלם תמונת פרופיל איכותית מבלי להתאמץ.</div>
                            <div>לחלופין, ניתן גם לפנות לגורם מקצועי שידע לסייע לך בזה.</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_2} onHide={modal_2_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>כיצד לפתוח דף עסקי מקצועי בפייסבוק</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <iframe src="https://player.vimeo.com/video/419489253" width="100%"
                                data-ready="true" allowfullscreen allow="autoplay; fullscreen" className='frame_height'
                            ></iframe>
                        </Col>
                        <Col sm={6}>
                            <div>נוסיף כתובת אתר אינטרנט ופרטי התקשרות נוספים ולסיום נכתוב ונעלה פוסטים ראשונים.</div>
                            <div>המייצרים עניין מקצועי. נכתוב על תחום המומחיות שלנו ונשתף את הקהל בתובנות מקצועיות, מסקנות וכו'.</div>
                            <div> נביא לקהל החדש ערך בחינם.</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_3} onHide={modal_3_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>כיצד לפתוח פרופיל עסקי מקצועי באינסטגרם</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <iframe src="https://player.vimeo.com/video/416839608" width="100%"
                                data-ready="true" allowfullscreen allow="autoplay; fullscreen" className='frame_height'
                            ></iframe>
                        </Col>
                        <Col sm={6}>
                            <div>לנוחותך יצרנו סרטון שמראה שלב אחר שלב</div>
                            <div>איך ניתן איך ניתן לפתוח דף עסקי מקצועי באינסטגרם מבלי להתאמץ.</div>
                            <div>לחלופין, ניתן גם לפנות לגורם מקצועי שידע לסייע לך בזה.</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_4} onHide={modal_4_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>כיצד נקים דף נחיתה/מיני סייט מזמין ומקצועי</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <iframe src="https://player.vimeo.com/video/950355250" width="100%"
                                data-ready="true" allowfullscreen allow="autoplay; fullscreen" className='frame_height'
                            ></iframe>
                        </Col>
                        <Col sm={6}>
                            <div>לנוחותך יצרנו עבורך סרטון שמראה שלב אחר שלב</div>
                            <div>איך ניתן להקים דף נחיתה/ מיניסייט בלי להתאמץ.</div>
                            <div>לחלופין ניתן לפנות לגורם מקצועי שידע לייצר את הלוגו עבורך.</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_5} onHide={modal_5_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>כיצד לצלם סרטון תדמית אפקטיבי</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <iframe src="https://player.vimeo.com/video/417935621" width="100%"
                                data-ready="true" allowfullscreen allow="autoplay; fullscreen" className='frame_height'
                            ></iframe>
                        </Col>
                        <Col sm={6}>
                            <div>לנוחותך יצרנו עבורך סרטון שמראה שלב אחר שלב</div>
                            <div>איך ניתן לצלם סרטון תדמית בלי להתאמץ.</div>
                            <div>לחלופין ניתן לפנות לגורם מקצועי שידע לייצר את הלוגו עבורך.</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </div >);
}

export default PropertyMarketings;