import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../App.css';
import Footer from './footer';
import { Col, Row, Accordion, Form, Modal, Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsQuestionCircle } from 'react-icons/bs';
import { BsFillXCircleFill } from 'react-icons/bs';
import { HiPencil } from 'react-icons/hi';
import { BsPlusCircle } from 'react-icons/bs';


function BusinessProducts({ AccordionOpen, setAccordionOpen,
    user_details, refresh_api, setRefresh_api, user_products, setModal_spinner }) {

    const [profit, setProfit] = useState();

    const [name_product, setName_product] = useState();
    const [profit_product, setProfit_product] = useState();
    const [name_expense, setName_expense] = useState();
    const [total_expense, setTotal_expense] = useState();

    const [name_product_update, setName_product_update] = useState();
    const [profit_product_update, setProfit_product_update] = useState();
    const [name_expense_update, setName_expense_update] = useState();
    const [total_expense_update, setTotal_expense_update] = useState();

    const [product_update_id, setProduct_update_id] = useState();

    const [modal_new_product, setModal_new_product] = useState(false);
    const [modal_update_product, setModal_update_product] = useState(false);
    const newProductClose = () => setModal_new_product(false);
    const updateProductClose = () => setModal_update_product(false);

    const navigate = useNavigate();

    const digit_3 = (number) => {
        if (number)
            return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        else
            return number;
    }

    const submit_profit = () => {
        setModal_spinner(true);
        axios.post('/api_profit', {
            profit: profit,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
            })
    }

    const submit_new_product = () => {
        if (name_product && profit_product && name_expense && total_expense) {
            setModal_spinner(true);
            axios.post('/api_new_product', {
                name_product: name_product,
                profit_product: profit_product,
                name_expense: name_expense,
                total_expense: total_expense,
                total_profit: parseInt(profit_product) + parseInt(total_expense),
            }, { withCredentials: true })
                .then(function () {
                    newProductClose();
                    setRefresh_api(refresh_api + 1);
                    setModal_spinner(false);
                    toast.success("המוצר נוסף בהצלחה");
                })
                .catch(function (error) {
                    if (error.response.status == 401) { navigate("/login") }
                    console.log(error);
                    setModal_spinner(false);
                })
        }
        else {
            toast.error("יש למלא את כל השדות");
        }
    }

    const submit_update_product = () => {
        if (name_product_update !== "" && profit_product_update !== "" &&
            name_expense_update !== "" && total_expense_update !== "") {
            setModal_spinner(true);
            axios.post('/api_update_product', {
                id: product_update_id,
                name_product: name_product_update,
                profit_product: profit_product_update,
                name_expense: name_expense_update,
                total_expense: total_expense_update,
                total_profit: parseInt(profit_product_update) + parseInt(total_expense_update)
            }, { withCredentials: true })
                .then(function () {
                    updateProductClose();
                    setRefresh_api(refresh_api + 1);
                    setModal_spinner(false);
                    toast.success("המוצר עודכן בהצלחה");
                })
                .catch(function (error) {
                    if (error.response.status == 401) { navigate("/login") }
                    console.log(error);
                    setModal_spinner(false);
                })
        }
        else {
            toast.error("יש למלא את כל השדות");
        }
    }

    const delete_product = (product_id) => {
        setModal_spinner(true);
        axios.post('/api_delete_product', {
            id: product_id,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("נמחק בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    let text_1 = "שם המוצר ומאפייניו.";
    text_1 += "\n";
    text_1 += "לדוגמה:";
    text_1 += "\n";
    text_1 += "• עוגת יום הולדת";
    text_1 += "\n";
    text_1 += "• שיעור פרטי במתמטיקה";

    let text_2 = " כמה אתם מתכוונים להרוויח מהמוצר.";
    text_2 += "\n";
    text_2 += "לדוגמה:";
    text_2 += "\n";
    text_2 += '• סה"כ רווח למוצר 125₪';
    text_2 += "\n";
    text_2 += '• סה"כ רווח לשיעור 115₪';

    let text_3 = " תפרטו את ההוצאות לייצור המוצר.";
    text_3 += "\n";
    text_3 += "לדוגמה:";
    text_3 += "\n";
    text_3 += '• חומרי גלם - 45₪';
    text_3 += "\n";
    text_3 += '• עלויות משלוח - 15₪';
    text_3 += "\n";
    text_3 += '• עלויות נסיעות וחניות - 20₪';
    text_3 += "\n";
    text_3 += '• הוצאות אירוח לפגישות - 50₪';

    let text_4 = " מחיר סופי למוצר המחושב מהרווח הרצוי וההוצאות.";
    text_4 += "\n";
    text_4 += "לדוגמה:";
    text_4 += "\n";
    text_4 += '• רווח למכירה אחת - 195₪';
    text_4 += "\n";
    text_4 += '• מחיר לשעה - 250₪';
    text_4 += "\n";
    text_4 += '• מחיר ללקוח - 100₪';

    return (
        <div className='BusinessProducts'>
            <div className='title_head'>
                <div className='div_title_head div_color_p'>
                    <div className='title_head_text color_p'>סל מוצרים ויעדים חודשיים</div>
                    <div className='title_head_text_2'>בניית סל מוצרים וחישוב רווחיות לעסק</div>
                </div>
            </div>
            <div className='aco'>
                <Accordion activeKey={AccordionOpen}>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header onClick={() => { AccordionOpen == 4 ? setAccordionOpen(["99"]) : setAccordionOpen(["4"]) }}>רווח מתוכנן</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div> בשלב זה נבקשכם לבחור את גובה הרווח שאתם מעוניינים ליצור כתוצאה מהעסק שלכם</div>
                                        <div>    הכוונה היא לסכום שישאר בידכם לאחר ניקוי הוצאות שיהיו לכם כדי להגיע לרווח. </div>
                                        <div>  בשלבים הבאים נלמד לחשב את ההוצאות ואז תוכלו לדעת בכמה עליכם למכור את המוצר או השרות אותו תשווקו.</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} className='no_padding'>
                                        <div>
                                            <Form>
                                                <Form.Group className="mb-3">
                                                    <Form.Label><div className='aco_body_2'>הזנת פירוט הרווח המתוכנן שלכם</div></Form.Label>
                                                    <Form.Control type="text" className='form_control_radus' size='sm'
                                                        defaultValue={user_details.profit}
                                                        onChange={(e) => setProfit(e.target.value)} />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["5"]); submit_profit() }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5" className='aco_bottom'>
                        <Accordion.Header onClick={() => { AccordionOpen == 5 ? setAccordionOpen(["99"]) : setAccordionOpen(["5"]) }}>המוצרים</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>או יחידות השירות שעליכם למכור על מנת להגיע לרווח המתווכנן</div>
                                    </Col>
                                </Row>
                                <div>
                                    <div className='aco_body_2'>פירוט מספר המוצרים ועלותם</div>
                                    {window.innerWidth > 800 ?
                                        <Table bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>המוצר
                                                        <span class="hovertext" data-hover={text_1}>
                                                            <BsQuestionCircle className='icon_question_2' />
                                                        </span>
                                                    </th>
                                                    <th>פירוט הוצאות
                                                        <span class="hovertext" data-hover={text_3}>
                                                            <BsQuestionCircle className='icon_question_2' />
                                                        </span>
                                                    </th>
                                                    <th>סה"כ הוצאות</th>
                                                    <th>רווח מהמוצר
                                                        <span class="hovertext" data-hover={text_2}>
                                                            <BsQuestionCircle className='icon_question_2' />
                                                        </span>
                                                    </th>
                                                    <th>מחיר סופי למוצר
                                                        <span class="hovertext" data-hover={text_4}>
                                                            <BsQuestionCircle className='icon_question_2' />
                                                        </span>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {user_products.map((product, index) => (
                                                    <tr key={index} >
                                                        <td xs={3} className='text_table'>{product.name_product}</td>
                                                        <td xs={2} className='text_table'>{product.name_expense}</td>
                                                        <td xs={2} className='text_table'>₪{digit_3(product.total_expense)}</td>
                                                        <td xs={2} className='text_table'>₪{digit_3(product.profit_product)}</td>
                                                        <td xs={2} className='text_table'>₪{digit_3(product.total_profit)}</td>
                                                        <td xs={2} className='text_table'>
                                                            <button onClick={() => {
                                                                setModal_update_product(true);
                                                                setName_product_update(product.name_product);
                                                                setProfit_product_update(product.profit_product);
                                                                setName_expense_update(product.name_expense);
                                                                setTotal_expense_update(product.total_expense);
                                                                setProduct_update_id(product._id)
                                                            }}
                                                                className='button_add_product_target'><HiPencil /></button>
                                                            <button className='button_add_product_target' onClick={() => delete_product(product._id)}>
                                                                <BsFillXCircleFill className='button_close' /></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td xs={3} className='text_table'>
                                                        {user_products.length == 0 ?
                                                            <button className='button_add_product_target' onClick={() => setModal_new_product(true)}>
                                                                <BsPlusCircle className='icon_add_product_target' />הוספת מוצר</button>
                                                            : <button className='button_add_product_target' onClick={() => setModal_new_product(true)}>
                                                                <BsPlusCircle className='icon_add_product_target' />הוספת מוצר נוסף</button>}
                                                    </td>
                                                    <td className='text_table'></td>
                                                    <td className='text_table'></td>
                                                    <td className='text_table'></td>
                                                    <td className='text_table'></td>
                                                    <td className='text_table'></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        :
                                        <div>
                                            <Table bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>המוצר</th>
                                                        <th>רווח</th>
                                                        <th>הוצאות</th>
                                                        <th>מחיר</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {user_products.map((product, index) => (
                                                        <tr key={index} >
                                                            <td xs={3} className='text_table'>{product.name_product}</td>
                                                            <td xs={2} className='text_table'>₪{digit_3(product.profit_product)}</td>
                                                            <td xs={2} className='text_table'>₪{digit_3(product.total_expense)}</td>
                                                            <td xs={2} className='text_table'>₪{digit_3(product.total_profit)}</td>
                                                            <td xs={2} className='text_table'>
                                                                <button onClick={() => {
                                                                    setModal_update_product(true);
                                                                    setName_product_update(product.name_product);
                                                                    setProfit_product_update(product.profit_product);
                                                                    setName_expense_update(product.name_expense);
                                                                    setTotal_expense_update(product.total_expense);
                                                                    setProduct_update_id(product._id)
                                                                }}
                                                                    className='button_add_product_target'><HiPencil /></button>
                                                                <span className='div_button_close'>
                                                                    <button className='button_add_product_target' onClick={() => delete_product(product._id)}>
                                                                        <BsFillXCircleFill className='button_close' /></button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <div className='div_button_add'>
                                                <button className='button_add_product_target' onClick={() => setModal_new_product(true)}>
                                                    <BsPlusCircle className='icon_add_product_target' />הוספת מוצר</button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["6"]); navigate('/property_marketings') }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <Footer />

            <Modal show={modal_new_product} onHide={newProductClose} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>הוספת מוצר</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className='label_modal'>שם המוצר
                                <span class="hovertext" data-hover={text_1}>
                                    <BsQuestionCircle className='icon_question_2' />
                                </span>
                            </Form.Label>
                            <Form.Control type="text" onChange={(e) => setName_product(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label_modal'>פירוט הוצאות
                                <span class="hovertext" data-hover={text_3}>
                                    <BsQuestionCircle className='icon_question_2' />
                                </span>
                            </Form.Label>
                            <Form.Control type="text" onChange={(e) => setName_expense(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label_modal'>סה"כ הוצאות</Form.Label>
                            <Form.Control type="number" onChange={(e) => setTotal_expense(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label_modal'>רווח מהמוצר
                                <span class="hovertext" data-hover={text_2}>
                                    <BsQuestionCircle className='icon_question_2' />
                                </span>
                            </Form.Label>
                            <Form.Control type="number" onChange={(e) => setProfit_product(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-grid'>
                        <button className='button_save' onClick={submit_new_product}>הוסף מוצר זה</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={modal_update_product} onHide={updateProductClose} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>עריכת מוצר</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label className='label_modal'>שם המוצר</Form.Label>
                            <Form.Control type="text" defaultValue={name_product_update} onChange={(e) => setName_product_update(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label_modal'>רווח מהמוצר</Form.Label>
                            <Form.Control type="number" defaultValue={profit_product_update} onChange={(e) => setProfit_product_update(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label_modal'>פירוט הוצאות</Form.Label>
                            <Form.Control type="text" defaultValue={name_expense_update} onChange={(e) => setName_expense_update(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className='label_modal'>סה"כ הוצאות</Form.Label>
                            <Form.Control type="number" defaultValue={total_expense_update} onChange={(e) => setTotal_expense_update(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-grid'>
                        <button className='button_save' onClick={submit_update_product}>עדכן</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </div >);
}

export default BusinessProducts;