import React, { useState, useRef } from 'react';
import '../App.css';
import Footer from './footer';
import axios from 'axios';
import { Col, Row, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';


function ContactUs() {
    const [name, setName] = useState();
    const [business_name, setBusiness_name] = useState();
    const [phone, setPhone] = useState();
    const [message, setMessage] = useState();

    const navigate = useNavigate();

    const testRef = useRef(null);
    const scrollToElement = () => testRef.current.scrollIntoView();

    const sendLead = () => {
        if (name && business_name && phone) {
            axios({
                method: 'post',
                url: '/api_new_lead',
                data: {
                    name: name,
                    business_name: business_name,
                    phone: phone,
                    message: message,
                }, withCredentials: true
            })
                .then((response) => {
                    if (response.status == 200) {
                        Swal.fire({
                            title: "הבקשה נשלחה בהצלחה !",
                            text: "לאחר בדיקה של הנציג שלנו נחזור אליך במייל",
                            icon: 'success',
                            confirmButtonText: 'תודה',
                            confirmButtonColor: '#f3be17'
                        })
                    }
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        toast.error("שגיאה, יש לנסות שוב מאוחר יותר");
                        console.log(error);
                    }
                })
        }
        else { toast.error("יש למלא את כל השדות"); }
    };

    return (
        <div className='ContactUs'>
            <div className='title_head'>
                <div className='div_title_head div_color_c'>
                    <div className='title_head_text color_c'>ייעוץ ללא עלות ממאמן עסקי מוסמך</div>
                    <div className='title_head_text_3'>קחו את העסק שלכם לשלב הבא עם ייעוץ מקצועי והכוונה עסקית
                        <span className='text_bolt'> ללא עלות</span></div>
                </div>
            </div>


            <div className='contact_1'>
                <Row>
                    <Col sm={4}>
                        <img className='man' src='man.webp'></img>
                    </Col>
                    <Col sm={4}>
                        <div className='contact_title'>חשובה לנו ההצלחה שלך!</div>
                        {window.innerWidth > 800 ?
                            <div>
                                <hr className='contact_hr'></hr>
                                <div className='contact_text'>
                                    <div>מולטי יו נוצרה בכדי לאפשר לעסקים בישראל להצליח יותר</div>
                                    <div>במטרה לעזור לך להגיע למטרה הזו - אנו מציעים שירות חסר תקדים!</div>
                                    <div>פשוט ממלאים פרטים ומקבלים שיחה ממאמן עסקי מוסמך - שיעבור אתכם על שלבי</div>
                                    <div>הקמת העסק וידריך אתכם איך העסק שלכם יכול להיות ריווחי יותר.</div>
                                </div>
                            </div>
                            :
                            <div className='contact_text'>
                                <div>מולטי יו נוצרה בכדי לאפשר לעסקים בישראל להצליח יותר</div>
                                <div>במטרה לעזור לך להגיע למטרה הזו</div>
                                <div>אנו מציעים שירות חסר תקדים!</div>
                                <div className='contact_text_2'>
                                    <div>פשוט ממלאים פרטים ומקבלים שיחה ממאמן עסקי מוסמך</div>
                                    <div>שיעבור אתכם על שלבי הקמת העסק וידריך אתכם איך</div>
                                    <div> העסק שלכם יכול להיות ריווחי יותר.</div>
                                </div>
                            </div>}
                        <div className='contact_text_bolt'>ללא עלות וללא התחייבות!</div>
                        <Row>
                            <Col sm={3}></Col>
                            <Col sm={6}>
                                <div className='d-grid div_button_contact'>
                                    <button type='button' className='button_contact' onClick={scrollToElement}>תאמו שיחת ייעוץ עכשיו</button>
                                </div>
                            </Col>
                            <Col sm={3}></Col>
                        </Row>
                    </Col>
                    <Col sm={4}>
                        <img className='arrow' src='arrow.png'></img>
                    </Col>
                </Row>
            </div>

            <Row>
                <Col sm={6} className='no_padding'>
                    <div className='contact_2'>
                        <div className='contact_title'>אופי הייעוץ</div>
                        <hr className='contact_hr_2'></hr>
                        <Row>
                            <Col sm={9} xs={8} className='no_padding'>
                                <div className='contact_text'>
                                    <div> במטרה לקדם את עסקך בנינו מערכת שלמה שמדריכה אותך צעד אחרי צעד במה צריך להתמקד ואיך לחשב הכל במדוייק.
                                        כדי לעזור לך לקחת את הידע הזה מהבנה ליישום - המאמן שלך יעבור איתך על כל הפרטים שמילאת
                                        במערכת ויתן לך את הידע והעצות הדרושות בכדי להוציא את התיאוריה לפועל ולייצר רווחים אמיתיים בזכות העסק שלך.</div>
                                </div>
                            </Col>
                            <Col sm={3} xs={4} className='no_padding'>
                                <img className='whistle2' src='whistle2.webp'></img>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm={6} className='no_padding'>
                    <div className='contact_3'>
                        <div className='contact_title'>התאמה אישית</div>
                        <hr className='contact_hr_2'></hr>
                        <Row>
                            <Col sm={9} xs={8} className='no_padding'>
                                <div className='contact_text'>
                                    <div> אנו מעסיקים עשרות מאמנים עסקיים המתמחים במגוון תחומים,
                                        כך שהיועץ שיבחר לעבוד איתך יהיה מותאם באופן אישי לך ולעסקך.
                                        כך תהיה בטוח שאתה בידיים טובות ושעסקך בדרך למעלה!</div>
                                </div>
                            </Col>
                            <Col sm={3} xs={4} className='no_padding'>
                                <img className='whistle3' src='settings.webp'></img>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <div className='contact_4' ref={testRef}>
                <div className='contact_title'>למה אתם מחכים?</div>
                <hr className='contact_hr'></hr>
                <div className='contact_text'>
                    <div>הזדמנות כזו לא מקבלים כל יום - אבל היום זה היום שלכם להצליח.</div>
                    <div>מלאו פרטי יצירת קשר ממש עכשיו וממשו את ההזדמנות שלכם להפוך לעסק מצליח יותר </div>
                </div>
                <div className='contact_form'>
                    <Row>
                        <Col sm={2}></Col>
                        <Col sm={8}>
                            <Form>
                                <Row>
                                    <Col sm={4} className='no_padding'>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" className='form_control_radus_2' size='sm' placeholder="שם מלא" onChange={(e) => setName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4} className='no_padding'>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" className='form_control_radus_2' size='sm' placeholder="שם העסק" onChange={(e) => setBusiness_name(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4} className='no_padding'>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" className='form_control_radus_2' size='sm' placeholder="טלפון ליצירת קשר" onChange={(e) => setPhone(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" className='form_control_radus_2' size='sm'
                                        as="textarea" rows={3} placeholder="תספרו למאמן על הקשיים שלכם" onChange={(e) => setMessage(e.target.value)} />
                                </Form.Group>
                            </Form>
                            <Row>
                                <Col sm={4} className='no_padding'></Col>
                                <Col sm={4} className='no_padding'>
                                    <div className='d-grid div_button_contact'>
                                        <button type='button' className='button_contact' onClick={sendLead}>צרו איתי קשר!</button>
                                    </div>
                                </Col>
                                <Col sm={4} className='no_padding'></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Col sm={2}></Col>
                </div>
            </div>


            <Footer />

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </div >
    );
}

export default ContactUs;