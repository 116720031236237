import React, { useState } from 'react';
import '../App.css';
import { Col, Row } from 'react-bootstrap';


function Footer() {

    return (
        <div className='Footer'>
            <hr className='footer_hr'></hr>
            <div className='footer'>
                <Row>
                    <Col sm={6} className='no_padding no_padding_desk'><div className='footer_left'>כל הזכויות שמורות ל-Multi-U, מוצר מבית תות תקשורת ותוצאות, 2023.</div></Col>
                    <Col sm={6} className='no_padding no_padding_desk'>
                        <div className='footer_right'>
                            <a href='https://www.tut-coach.com/%d7%94%d7%a1%d7%93%d7%a8%d7%99-%d7%a0%d7%92%d7%99%d7%a9%d7%95%d7%aa/' className='footer_link' target="_blank">הצהרת נגישות</a>
                            <a href='https://www.tut-coach.com/%d7%9e%d7%93%d7%99%d7%a0%d7%99%d7%95%d7%aa-%d7%a4%d7%a8%d7%98%d7%99%d7%95%d7%aa/' className='footer_link' target="_blank">מדיניות פרטיות</a>
                            <a href='https://www.tut-coach.com/' target="_blank"><img src='toot.png' width={30} className='footer_image'></img></a>
                            <a href='https://www.facebook.com/tutcoachofficial/' target="_blank"><img src='facebook.png' width={18} className='footer_image'></img></a>
                            <a href='https://www.instagram.com/1alongal/' target="_blank"><img src='instagram.png' width={18} className='footer_image'></img></a>
                            <a href='https://www.youtube.com/user/alongaltut' target="_blank"><img src='youtube.png' width={18} className='footer_image'></img></a>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='footer_2'>
                <Row>
                    <Col sm={6} className='no_padding no_padding_desk'><div className='footer_left'>
                        אפיון ועיצוב: <a href='https://www.advadv.co.il/' className='footer_left' target="_blank">Advanced, פתרונות פרסום מתקדמים</a></div></Col>
                    <Col sm={6} className='no_padding no_padding_desk'><div className='footer_right'>
                        תכנות ובניה: <a href='https://www.linkedin.com/in/yakov-horwitz-47b080235/' className='footer_right' target="_blank">Yakov Horwitz</a></div></Col>
                </Row>
            </div>
        </div >
    );
}

export default Footer;