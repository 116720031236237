import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import '../App.css';
import Footer from './footer';
import { Col, Row, Table, Modal, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosCloseCircle } from 'react-icons/io';
import { HiPencil } from 'react-icons/hi';
import { FaCopy } from 'react-icons/fa';
import { BsFacebook } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { TbWorld } from 'react-icons/tb';
import { BsPlayCircleFill } from 'react-icons/bs';
import { BsPlusCircle } from 'react-icons/bs';
import { LuStars } from 'react-icons/lu';
import { BsQuestionCircle } from 'react-icons/bs';
import { BsFillXCircleFill } from 'react-icons/bs';


function Dashboard({ user_details, user_products, setAccordionOpen, refresh_api, 
    setRefresh_api, onboarding, setOnboarding, show_menu, setShow_menu, setModal_spinner}) {

    const [id_product_update, setId_product_update] = useState();
    const [product_update, setProduct_update] = useState();

    const [modal_update_product, setModal_update_product] = useState(false);
    const update_product_Close = () => setModal_update_product(false);

    const [modal_onboarding, setModal_onboarding] = useState(onboarding);

    const navigate = useNavigate();
    const date = new Date();


    const copy = (text_copy) => {
        navigator.clipboard.writeText(text_copy)
    }

    const digit_3 = (number) => {
        if (number)
            return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        else
            return 0;
    }

    const total_profit_percent = (user_products.reduce((n, { profit_product, amount }) =>
        n + profit_product * amount, 0) / user_details.profit) * 100;

    const total_profit = digit_3(user_products.reduce((n, { profit_product, amount }) => n + profit_product * amount, 0));

    const total_to_target = user_details.profit - user_products.reduce((n, { profit_product, amount }) => n + profit_product * amount, 0);

    const update_amount = () => {
        update_product_Close();
        setModal_spinner(true);
        axios.post('/api_product_amount', {
            id: id_product_update.id,
            amount: product_update
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const popover = (item) => {
        return (
            <Popover id="popover-basic">
                <Popover.Body>
                    <Row className='no_padding no_padding_desk'>
                        <Col xs={9} className='no_padding no_padding_desk'>
                            <div className='popover_bold'>עדיין אין לך {item}?</div>
                            <div>זו ההזדמנות שלך לקבל עזרה וייעוץ</div>
                            <div> ממאמן עסקי ללא עלות או התחייבות.</div>
                        </Col>
                        <Col xs={3} className='no_padding no_padding_desk'>
                            <div className='div_popover_link'><Link className='popover_link' to="/contact_us">לפרטים</Link></div>
                        </Col>
                    </Row>
                </Popover.Body>
            </Popover>
        )
    };

    return (
        <div className='Dashboard'>
            <div>
                {window.innerWidth > 800 ?
                    <div className='dashboard_title_head'>
                        <Row>
                            <Col xs={1}>
                                <div className='div_dashboard_logo'>
                                    <button className='button_dashboard_logo' to="/business_details"
                                        onClick={() => { navigate("/business_details"); setAccordionOpen(["2"]); setShow_menu(false); }}
                                    >{user_details.business_logo ? <img src={user_details.business_logo} className='dashboard_logo'></img> :
                                        <img src="./no_logo.png" className='dashboard_logo'></img>}</button>
                                </div>
                            </Col>
                            <Col xs={10}>
                                <div className='tytle_dashboard'>שמחים לראות אותך, <span className='bord_700'>{user_details.name}!</span></div>
                                <div className='tytle_dashboard_2'>נשארו רק כמה צעדים כדי לקדם את העסק שלך לשלב הבא.</div>
                            </Col>

                            <Col xs={1}>
                                <div className='div_button_nav_2'>
                                    <button className='button_nav_2' onClick={() => show_menu ? setShow_menu(false) : setShow_menu(true)}>
                                        <img src='kebab.png' width={5} /></button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <div className='dashboard_title_head_2'>
                            <Row>
                                <Col xs={2}>
                                    <div className='div_dashboard_logo'>
                                        <button className='button_dashboard_logo' to="/business_details"
                                            onClick={() => { navigate("/business_details"); setAccordionOpen(["2"]); setShow_menu(false); }}
                                        >{user_details.business_logo ? <img src={user_details.business_logo} className='dashboard_logo'></img> :
                                            <img src="./no_logo.png" className='dashboard_logo'></img>}</button>
                                    </div>
                                </Col>
                                <Col xs={10} className='no_padding'>
                                    <div className='tytle_dashboard'>שמחים לראות אותך, <span className='bord_700'>{user_details.name}!</span></div>
                                    <div className='tytle_dashboard_2'>נשארו רק כמה צעדים כדי לקדם את העסק שלך לשלב הבא.</div>
                                </Col>
                            </Row>
                        </div>
                    </div>}

                <div className='target'>
                    <div className='target_head'>
                        <Row>
                            <Col xs={6} className='no_padding'>
                                <div className='dashboard_head_div_1'>
                                    <div className='dashboard_head_text_1'>
                                        ניהול יעדים חודשיים
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} className='no_padding'>
                                <div className='div_button_edit'>
                                    <button className='button_edit'
                                        onClick={() => { navigate("/business_products"); setAccordionOpen(["99"]); setShow_menu(false); }}>
                                        <HiPencil className='icon_edit' /> עריכת היעדים</button></div></Col>
                        </Row>
                    </div>
                    <div className='dashbord_table'>
                        <Row>
                            <Col sm={3} className='no_padding'>
                                <div className='div_target'>
                                    <div className='target_text_1'>היעד החודשי:</div>
                                    <div>₪{digit_3(user_details.profit)}</div>
                                    <div className='div_pie'>
                                        <div className="pie" style={{ "--p": `${total_profit_percent}` }}>
                                            <div className='text_pie'>
                                                <div className='target_text_2'>הגעת ל:</div>
                                                <div className='target_text_3'>₪{total_profit}</div>
                                                {total_to_target && total_to_target > 0 ?
                                                    <div className='target_text_4'>רק עוד
                                                        <span> ₪{digit_3(total_to_target)} </span>
                                                        ליעד!</div>
                                                    :
                                                    <div className='target_text_5'>הגעת
                                                        ליעד!</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={9} className='no_padding'>
                                <div className='table_margin'>
                                    <Row className='table_title'>
                                        <Col xs={3}><div className='table_title_1'>המוצר</div></Col>
                                        <Col xs={3}><div className='table_title_2'>רווח</div>   </Col>
                                        <Col xs={3}><div className='table_title_3'>כמות</div></Col>
                                        <Col xs={3}><div className='table_title_4'>סה"כ</div></Col>
                                    </Row>
                                </div>
                                <div className='dashbord_table_2'>
                                    <Table bordered hover>
                                        <tbody>
                                            {user_products.map((product, index) => (
                                                <tr key={index} >
                                                    <td xs={2} className='text_table'>{product.name_product}</td>
                                                    <td xs={2} className='text_table'>₪{digit_3(product.profit_product)}</td>
                                                    <td xs={2} className='text_table'>{product.amount}</td>
                                                    <td xs={2} className='text_table'>₪{digit_3(product.profit_product * product.amount)}</td>
                                                    <td xs={2} className='text_table td_close'>
                                                        <button className='button_add_product_target button_td_close'
                                                            onClick={() => {
                                                                setId_product_update({ id: product._id, amount: product.amount });
                                                                setModal_update_product(true)
                                                            }}><HiPencil />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='div_button_add'>
                                    <button onClick={() => { navigate('../business_products'); setAccordionOpen(["5"]); }} className='button_add_product_target_3'>
                                        <BsPlusCircle className='icon_add_product_target' />צור מוצר חדש</button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Row>
                    <Col sm={5} className='no_padding'>
                        <div className='target2'>
                            <div className='target_head'>
                                <Row>
                                    <Col xs={6} className='no_padding'>
                                        <div className='dashboard_head_div_2'>
                                            <div className='dashboard_head_text_2'>
                                                פרטי העסק
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6} className='no_padding'><div className='div_button_edit'><button className='button_edit'
                                        onClick={() => { navigate("/business_details"); setAccordionOpen(["99"]); setShow_menu(false); }}
                                    ><HiPencil className='icon_edit' /> עריכת פרטי העסק</button></div></Col>
                                </Row>
                            </div>
                            <div className='div_dashboard_logo_2 d-grid'>
                                {user_details.business_logo ? <img src={user_details.business_logo} className='dashboard_logo_2'></img> :
                                    <img src="./no_logo.png" className='dashboard_logo_2'></img>}
                            </div>
                            <div className='dashboard_business_name'>{user_details.business_name}</div>
                            <div className='dashboard_slogan'>{user_details.slogan}</div>
                        </div>
                    </Col>
                    <Col sm={7} className='no_padding'>
                        <div className='target3'>
                            <div className='target_head'>
                                <Row>
                                    <Col xs={7} className='no_padding'>
                                        <div className='dashboard_head_div_3'>
                                            <div className='dashboard_head_text_3'>
                                                נכסים אינטרנטיים
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={5} className='no_padding'><div className='div_button_edit'><button className='button_edit'
                                        onClick={() => { navigate("/property_marketings"); setAccordionOpen(["99"]); setShow_menu(false); }}
                                    ><HiPencil className='icon_edit' /> עריכת הנכסים</button></div></Col>
                                </Row>
                            </div>
                            <div className='dashboard_property'>
                                <Row className='no_padding'>
                                    <Col sm={10} xs={11}>
                                        {user_details.facebook ?
                                            <div className='dashboard_property_text'>
                                                <BsFacebook className='dashboard_property_icon' />
                                                {user_details.facebook}
                                                <div className='div_popover_button'>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={popover("דף פייסבוק")}>
                                                        <button className='popover_button'><LuStars className='icon_question' /></button>
                                                    </OverlayTrigger>
                                                </div>
                                            </div> :
                                            window.innerWidth > 800 ?
                                                <div className='dashboard_property_none'>
                                                    <BsFacebook className='dashboard_property_icon' />
                                                    עוד לא הזנת דף פייסבוק!
                                                    <button className='button_link'
                                                        onClick={() => { navigate("/property_marketings"); setAccordionOpen(["6"]) }}>
                                                        כדי לטפל בזה עכשיו לחץ כאן</button>
                                                    <div className='div_popover_button'>
                                                        <OverlayTrigger trigger="click" placement="right" overlay={popover("דף פייסבוק")}>
                                                            <button className='popover_button'><LuStars className='icon_question' /></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                :
                                                <div className='dashboard_property_none'>
                                                    <BsFacebook className='dashboard_property_icon' />
                                                    <button className='button_link'
                                                        onClick={() => { navigate("/property_marketings"); setAccordionOpen(["6"]) }}>
                                                        כדי להוסיף קישור של דף פייסבוק לחץ כאן</button>
                                                    <div className='div_popover_button'>
                                                        <OverlayTrigger trigger="click" placement="right" overlay={popover("דף פייסבוק")}>
                                                            <button className='popover_button'><LuStars className='icon_question' /></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                        }
                                    </Col>
                                    <Col sm={2} xs={1}>
                                        {window.innerWidth > 800 ?
                                            <div className='dashboard_copy'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.facebook)}><FaCopy /> העתק</button></div>
                                            : <div className='dashboard_copy'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.facebook)}><FaCopy /></button></div>}
                                    </Col>
                                </Row>
                                <Row className='no_padding'>
                                    <Col sm={10} xs={11}>
                                        {user_details.instagram ?
                                            <div className='dashboard_property_text'>
                                                <AiFillInstagram className='dashboard_property_icon_2' />
                                                {user_details.instagram}   <div className='div_popover_button'>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={popover("פרופיל אינסטגרם")}>
                                                        <button className='popover_button'><LuStars className='icon_question' /></button>
                                                    </OverlayTrigger>
                                                </div>
                                            </div> :
                                            window.innerWidth > 800 ?
                                                <div className='dashboard_property_none'>
                                                    <AiFillInstagram className='dashboard_property_icon_2' />
                                                    עוד לא הזנת פרופיל אינסטגרם!
                                                    <button className='button_link'
                                                        onClick={() => { navigate("/property_marketings"); setAccordionOpen(["7"]) }}>
                                                        כדי לטפל בזה עכשיו לחץ כאן</button>
                                                    <div className='div_popover_button'>
                                                        <OverlayTrigger trigger="click" placement="right" overlay={popover("פרופיל אינסטגרם")}>
                                                            <button className='popover_button'><LuStars className='icon_question' /></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                :
                                                <div className='dashboard_property_none'>
                                                    <AiFillInstagram className='dashboard_property_icon_2' />
                                                    <button className='button_link'
                                                        onClick={() => { navigate("/property_marketings"); setAccordionOpen(["7"]) }}>
                                                        כדי להוסיף קישור של פרופיל אינסטגרם לחץ כאן</button>
                                                    <div className='div_popover_button'>
                                                        <OverlayTrigger trigger="click" placement="right" overlay={popover("פרופיל אינסטגרם")}>
                                                            <button className='popover_button'><LuStars className='icon_question' /></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                        }
                                    </Col>
                                    <Col sm={2} xs={1}>
                                        {window.innerWidth > 800 ?
                                            <div className='dashboard_copy'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.facebook)}><FaCopy /> העתק</button></div>
                                            : <div className='dashboard_copy'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.facebook)}><FaCopy /></button></div>}
                                    </Col>
                                </Row>
                                <Row className='no_padding'>
                                    <Col sm={10} xs={11}>
                                        {user_details.minisite ?
                                            <div className='dashboard_property_text'>
                                                <TbWorld className='dashboard_property_icon_2' />
                                                {user_details.minisite}
                                                <div className='div_popover_button'>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={popover("מיניסייט")}>
                                                        <button className='popover_button'><LuStars className='icon_question' /></button>
                                                    </OverlayTrigger>
                                                </div>
                                            </div> :
                                            window.innerWidth > 800 ?
                                                <div className='dashboard_property_none'>
                                                    <TbWorld className='dashboard_property_icon_2' />
                                                    עוד לא הזנת מיניסייט!
                                                    <button className='button_link'
                                                        onClick={() => { navigate("/property_marketings"); setAccordionOpen(["8"]) }}>
                                                        כדי לטפל בזה עכשיו לחץ כאן</button>
                                                    <div className='div_popover_button'>
                                                        <OverlayTrigger trigger="click" placement="right" overlay={popover("מיניסייט")}>
                                                            <button className='popover_button'><LuStars className='icon_question' /></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                :
                                                <div className='dashboard_property_none'>
                                                    <TbWorld className='dashboard_property_icon_2' />
                                                    <button className='button_link'
                                                        onClick={() => { navigate("/property_marketings"); setAccordionOpen(["8"]) }}>
                                                        כדי להוסיף קישור של מיניסייט לחץ כאן</button>
                                                    <div className='div_popover_button'>
                                                        <OverlayTrigger trigger="click" placement="right" overlay={popover("מיניסייט")}>
                                                            <button className='popover_button'><LuStars className='icon_question' /></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                        }
                                    </Col>
                                    <Col sm={2} xs={1}>
                                        {window.innerWidth > 800 ?
                                            <div className='dashboard_copy'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.facebook)}><FaCopy /> העתק</button></div>
                                            : <div className='dashboard_copy'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.facebook)}><FaCopy /></button></div>}
                                    </Col>
                                </Row>

                                <Row className='no_padding'>
                                    <Col sm={10} xs={11}>
                                        {user_details.youtube ?
                                            <div className='dashboard_property_text'>
                                                <BsPlayCircleFill className='dashboard_property_icon' />
                                                {user_details.youtube}
                                                <div className='div_popover_button'>
                                                    <OverlayTrigger trigger="click" placement="right" overlay={popover("סרטון תדמית")}>
                                                        <button className='popover_button'><LuStars className='icon_question' /></button>
                                                    </OverlayTrigger>
                                                </div>
                                            </div> :
                                            window.innerWidth > 800 ?
                                                <div className='dashboard_property_none'>
                                                    <BsPlayCircleFill className='dashboard_property_icon' />
                                                    עוד לא הזנת סרטון תדמית!
                                                    <button className='button_link'
                                                        onClick={() => { navigate("/property_marketings"); setAccordionOpen(["9"]) }}>
                                                        כדי לטפל בזה עכשיו לחץ כאן</button>
                                                    <div className='div_popover_button'>
                                                        <OverlayTrigger trigger="click" placement="right" overlay={popover("סרטון תדמית")}>
                                                            <button className='popover_button'><LuStars className='icon_question' /></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                :
                                                <div className='dashboard_property_none'>
                                                    <BsPlayCircleFill className='dashboard_property_icon' />
                                                    <button className='button_link'
                                                        onClick={() => { navigate("/property_marketings"); setAccordionOpen(["9"]) }}>
                                                        כדי להוסיף קישור של סרטון תדמית לחץ כאן</button>
                                                    <div className='div_popover_button'>
                                                        <OverlayTrigger trigger="click" placement="right" overlay={popover("סרטון תדמית")}>
                                                            <button className='popover_button'><LuStars className='icon_question' /></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>}
                                    </Col>
                                    <Col sm={2} xs={1}>
                                        {window.innerWidth > 800 ?
                                            <div className='dashboard_copy'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.facebook)}><FaCopy /> העתק</button></div>
                                            : <div className='dashboard_copy'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.facebook)}><FaCopy /></button></div>}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className='target4'>
                    <div className='target_head'>
                        <Row>
                            <Col xs={6} className='no_padding'>
                                <div className='dashboard_head_div_4'>
                                    <div className='dashboard_head_text_4'>
                                        שיווק העסק
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} className='no_padding'><div className='div_button_edit'><button className='button_edit_2'
                                onClick={() => { navigate("/marketing_business"); setAccordionOpen(["99"]); setShow_menu(false); }}
                            ><HiPencil className='icon_edit' /> עריכת השיווק</button></div></Col>
                        </Row>
                    </div>
                    <div className='dashboard_marketing'>
                        <Row>
                            <Col sm={6}>
                                <div className='dashboard_div_marketing'>
                                    <Row>
                                        <Col xs={6} className='no_padding'>
                                            <div className='dashboard_div_text'> מסר שיווקי</div>
                                        </Col>
                                        <Col xs={6} className='no_padding'>
                                            <div className='dashboard_copy_1'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.marketing_message)}><FaCopy /> העתק</button></div>
                                        </Col>
                                    </Row>
                                    {user_details.marketing_message ?
                                        <div className='dashboard_div'>
                                            {user_details.marketing_message}
                                            <div className='div_popover_button_2'>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover("מסר שיווקי")}>
                                                    <button className='popover_button'><LuStars className='icon_question' /></button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        : <div className='dashboard_div_none'>
                                            <IoIosCloseCircle /> עוד לא הכנת מסר שיווקי!
                                            <button className='button_link' onClick={() => {
                                                navigate("/marketing_business");
                                                setAccordionOpen(["10"])
                                            }}>כדי לטפל בזה עכשיו לחץ כאן</button>
                                            <div className='div_popover_button_2'>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover("מסר שיווקי")}>
                                                    <button className='popover_button'><LuStars className='icon_question' /></button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>}
                                </div>

                                <div className='dashboard_div_marketing'>
                                    <Row>
                                        <Col xs={6} className='no_padding'>
                                            <div className='dashboard_div_text'> משפך שיווקי</div>
                                        </Col>
                                        <Col xs={6} className='no_padding'>
                                            <div className='dashboard_copy_1'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.marketing_funnel)}><FaCopy /> העתק</button></div>
                                        </Col>
                                    </Row>
                                    {user_details.marketing_funnel ?
                                        <div className='dashboard_div'>
                                            {user_details.marketing_funnel}
                                            <div className='div_popover_button_2'>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover("משפך שיווקי")}>
                                                    <button className='popover_button'><LuStars className='icon_question' /></button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        : <div className='dashboard_div_none'>
                                            <IoIosCloseCircle /> עוד לא הכנת משפך שיווקי!
                                            <button className='button_link' onClick={() => {
                                                navigate("/marketing_business");
                                                setAccordionOpen(["11"])
                                            }}>כדי לטפל בזה עכשיו לחץ כאן</button>
                                            <div className='div_popover_button_2'>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover("משפך שיווקי")}>
                                                    <button className='popover_button'><LuStars className='icon_question' /></button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>}
                                </div>

                                <div className='dashboard_div_marketing'>
                                    <Row>
                                        <Col xs={6} className='no_padding'>
                                            <div className='dashboard_div_text'> תכנית שיווקית</div>
                                        </Col>
                                        <Col xs={6} className='no_padding'>
                                            <div className='dashboard_copy_1'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.marketing_plan)}><FaCopy /> העתק</button></div>
                                        </Col>
                                    </Row>
                                    {user_details.marketing_plan ?
                                        <div className='dashboard_div'>
                                            {user_details.marketing_plan}
                                            <div className='div_popover_button_2'>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover("תכנית שיווקית")}>
                                                    <button className='popover_button'><LuStars className='icon_question' /></button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        : <div className='dashboard_div_none'>
                                            <IoIosCloseCircle /> עוד לא הכנת תכנית שיווקית!
                                            <button className='button_link' onClick={() => {
                                                navigate("/marketing_business");
                                                setAccordionOpen(["12"])
                                            }}>כדי לטפל בזה עכשיו לחץ כאן</button>
                                            <div className='div_popover_button_2'>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover("תכנית שיווקית")}>
                                                    <button className='popover_button'><LuStars className='icon_question' /></button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>}
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className='dashboard_div_marketing_2'>
                                    <Row>
                                        <Col xs={6} className='no_padding'>
                                            <div className='dashboard_div_text'>שיחת מכירה</div>
                                        </Col>
                                        <Col xs={6} className='no_padding'>
                                            <div className='dashboard_copy_1'><button className='dashboard_button_copy'
                                                onClick={() => copy(user_details.sales_call)}><FaCopy /> העתק</button></div>
                                        </Col>
                                    </Row>
                                    {user_details.sales_call ?
                                        <div className='dashboard_div_long'>
                                            {user_details.sales_call}
                                            <div className='div_popover_button_2'>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover("שיחת מכירה")}>
                                                    <button className='popover_button'><LuStars className='icon_question' /></button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        : <div className='dashboard_div_none_long'>
                                            <IoIosCloseCircle /> עוד לא הכנת שיחת מכירה!
                                            <button className='button_link' onClick={() => {
                                                navigate("/marketing_business");
                                                setAccordionOpen(["13"])
                                            }}>כדי לטפל בזה עכשיו לחץ כאן</button>
                                            <div className='div_popover_button_2'>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover("שיחת מכירה")}>
                                                    <button className='popover_button'><LuStars className='icon_question' /></button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div >

            <Modal show={modal_update_product} onHide={update_product_Close} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>עדכון כמות</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Control type="number" placeholder="כמות" min={0}
                                defaultValue={id_product_update ? id_product_update.amount : 0}
                                onChange={(e) => setProduct_update(e.target.value)} />
                        </Form.Group>
                        <Row>
                            <Col xs={6}></Col>
                            <Col xs={6}>
                                <div className='d-grid'>
                                    <button type='button' className='button_save' onClick={() => update_amount()}>שמור</button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={modal_onboarding} centered size="xl">
                <Modal.Header>
                    <Modal.Title><div className='div_onboarding_title'><div>ברוכים הבאים למולטי יו!</div></div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}></Col>
                        <Col sm={6}>
                            <div className='onboarding_text_1'>
                                <div className='onboarding_text_2'>בואו נתחיל להקים את העסק!</div>
                                <div>המערכת ריקה מתוכן כרגע, בכדי להשתמש בה עליכם להתחיל להזין פרטים אודות העסק שלכם.</div>
                                <div className='onboarding_text_3'>המערכת תסייע לכם שלב אחר שלב לבנות את העסק בצורה
                                    היעילה והנכונה ביותר, החל מבחירת השם ועד הקמת מערך שיווקי יעיל ועובד.</div>
                                <div className='onboarding_text_3'>
                                    אתם תוכלו לעבור אל תיק העסק או לחזור לתפריטי הקמת העסק בכל שלב
                                    באמצעות תפריט הצד ובמידה ותתקלו בקושי מסויים בשלבי ההקמה תוכלו להעזר
                                    בכפתור ה- <span><BsQuestionCircle className='onboarding_icon_1' /></span> לקבלת מידע נוסף
                                    או בכפתורי ה- <span><LuStars className='onboarding_icon_2' /></span> לקבלת שיחת ייעוץ ממאמן עסקי מוסמך ללא עלות או התחייבות!
                                </div>
                                <div className='onboarding_text_4'>אז למה אתם מחכים? בואו נתחיל לבנות את העסק שלכם!</div>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}>
                                        <div className='d-grid'><button className='button_save'
                                            onClick={() => { setModal_onboarding(false); setOnboarding(false); navigate('../business_details'); setAccordionOpen(["0"]); }}>
                                            מעבר לבניית העסק
                                        </button></div>
                                    </Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col sm={3}></Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Footer />

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />

        </div >);
}

export default Dashboard;