import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../App.css';
import Footer from './footer';
import { Col, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function UserDetails({ refresh_api, setRefresh_api, setModal_spinner }) {

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [multi_checked, setMulti_checked] = useState();
    const [toot_checked, setToot_checked] = useState();
    const [refresh_api_2, setRefresh_api_2] = useState(0);

    const navigate = useNavigate();

    //מביא את פרטי העסק
    useEffect(() => {
        axios.post('/user', {
        }, { withCredentials: true })
            .then(function (response) {
                setName(response.data[0].name);
                setEmail(response.data[0].email);
                setPassword(response.data[0].password);
                setMulti_checked(response.data[0].mailing_multi);
                setToot_checked(response.data[0].mailing_toot);
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
            })
    }, [refresh_api_2]);

    const update_details = () => {
        if (name && email && password) {
            setModal_spinner(true);
            axios.post('/update_user_details', {
                name: name,
                email: email,
                password: password,
            }, { withCredentials: true })
                .then(function () {
                    setRefresh_api_2(refresh_api_2 + 1);
                    setRefresh_api(refresh_api + 1);
                    setModal_spinner(false);
                    toast.success("עודכן בהצלחה");
                })
                .catch(function (error) {
                    if (error.response.status == 401) { navigate("/login") }
                    console.log(error);
                    setModal_spinner(false);
                })
        }
        else { toast.error("יש למלא את כל השדות"); }
    }

    const update_alerts = () => {
        setModal_spinner(true);
        axios.post('/update_user_alerts', {
            multi_checked: multi_checked,
            toot_checked: toot_checked,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api_2(refresh_api_2 + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const delete_user = () => {
        setModal_spinner(true);
        axios.post('/delete_user', {
            test: 0
        }, { withCredentials: true })
            .then(function () {
                document.cookie = 'token = ""';
                setModal_spinner(false);
                navigate("/");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    return (
        <div className='UserDetails'>
            <div className='title_head'>
                <div className='div_title_head_user'>
                    <div className='title_head_text_user'>עריכת פרטי המשתמש</div>
                </div>
            </div>

            <Row>
                <Col sm={7} className='no_padding'>
                    <div className='user_details_1'>
                        <div className='user_details_1_title'>פרטי התחברות</div>
                        <Row>
                            <Col sm={5}>
                                <div className='user_details_form'>
                                    <label className='user_details_lable'>שם פרטי</label>
                                    <input className='user_details_input' defaultValue={name} onChange={(e) => setName(e.target.value)} />
                                    <label className='user_details_lable'>דוא"ל</label>
                                    <input className='user_details_input' defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
                                    <label className='user_details_lable'>סיסמה</label>
                                    <input className='user_details_input' defaultValue={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </Col>
                            <Col sm={7}>
                                <div className='d-grid div_button_user_details'>
                                    <button type='button' className='button_user_details' onClick={update_details}>שמור שינויים</button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='user_details_2'>
                        <div className='user_details_1_title'>התראות</div>
                        <Row>
                            <Col sm={8}>
                                <div className='user_details_form'>
                                    <div className='div_lable'><input className='input_c' type='checkbox' label="option 1" id='multi' checked={multi_checked} onChange={() => multi_checked ? setMulti_checked(false) : setMulti_checked(true)} />
                                        <lable className='input_c_lable' for='multi'>ברצוני לקבל התראות לגביי מבצעים והטבות של Multi-U</lable></div>
                                    <div className='div_lable'><input className='input_c' type='checkbox' label="option 1" id='multi' checked={toot_checked} onChange={() => toot_checked ? setToot_checked(false) : setToot_checked(true)} />
                                        <lable className='input_c_lable' for='multi'>ברצוני לקבל התראות לגביי מבצעים והטבות של תות תקשורת ותוצאות</lable></div>
                                    <div className='text_private'>מולטי-יו תעבד את הנתונים שלך כדי לשלוח לך מידע על המוצרים והשירותים שלנו,
                                        מבצעים, סקרים, הגרלות, בהתבסס על האינטרס הלגיטימי שלנו ועדכונים מהיוצרים שאתה עוקב אחריהם,
                                        אם הסכמת לכך. הנתונים שלך לא ייחשפו לצדדים שלישיים.
                                        הם יועברו מחוץ לאיחוד האירופי בהתאם לתנאי מדיניות הפרטיות. אתה יכול לבטל את הסכמתך להתראות שלנו עם המחוון הראשון. </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className='d-grid div_button_user_details_2'>
                                    <button type='button' className='button_user_details' onClick={update_alerts}>שמור שינויים</button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='user_details_3'>
                        <div className='user_details_1_title'>סגירת משתמש</div>
                        <Row>
                            <Col sm={6}>
                                <div className='d-grid div_button_user_details_3'>
                                    <button type='button' className='button_user_details_3' onClick={delete_user}>ברצוני למחוק את המשתמש שלי</button>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className='text_delete'>במידה ותבחר לסגור את המשתמש מולטי יו לא תוכל להתחייב כי תשמור את הפרטים במידה ותרצה לשחזר אותו</div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Footer />

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </div >);
}

export default UserDetails;