import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import '../App.css';
import Footer from './footer';
import { Col, Row, Accordion, Form, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsQuestionCircle } from 'react-icons/bs';
import { BiSolidDownload } from 'react-icons/bi';
import { LuStars } from 'react-icons/lu';


function MarketingBusiness({ AccordionOpen, setAccordionOpen,
    user_details, refresh_api, setRefresh_api, setModal_spinner }) {

    const [marketing_message, setMarketing_message] = useState();
    const [marketing_funnel, setMarketing_funnel] = useState();
    const [marketing_plan, setmarketing_plan] = useState();
    const [sales_call, setSales_call] = useState();

    const [modal_1, setModal_1] = useState(false);
    const modal_1_close = () => setModal_1(false);
    const [modal_2, setModal_2] = useState(false);
    const modal_2_close = () => setModal_2(false);
    const [modal_3, setModal_3] = useState(false);
    const modal_3_close = () => setModal_3(false);
    const [modal_4, setModal_4] = useState(false);
    const modal_4_close = () => setModal_4(false);
    const [modal_5, setModal_5] = useState(false);
    const modal_5_close = () => setModal_5(false);

    const navigate = useNavigate();

    const submit_marketing_message = () => {
        setModal_spinner(true);
        axios.post('/api_marketing_message', {
            marketing_message: marketing_message,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const submit_marketing_funnel = () => {
        setModal_spinner(true);
        axios.post('/api_marketing_funnel', {
            marketing_funnel: marketing_funnel,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const submit_marketing_plan = () => {
        setModal_spinner(true);
        axios.post('/api_marketing_plan', {
            marketing_plan: marketing_plan,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    const submit_sales_call = () => {
        setModal_spinner(true);
        axios.post('/api_sales_call', {
            sales_call: sales_call,
        }, { withCredentials: true })
            .then(function () {
                setRefresh_api(refresh_api + 1);
                setModal_spinner(false);
                toast.success("עודכן בהצלחה");
            })
            .catch(function (error) {
                if (error.response.status == 401) { navigate("/login") }
                console.log(error);
                setModal_spinner(false);
            })
    }

    return (
        <div className='MarketingBusiness'>
            <div className='title_head'>
                <div className='div_title_head div_color_k'>
                    <div className='title_head_text color_k'>שיווק העסק</div>
                    <div className='title_head_text_2'>ייצור מערך שיווקי יעיל ומתקתק</div>
                </div>
            </div>
            <div className='aco'>
                <Accordion activeKey={AccordionOpen}>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header onClick={() => { AccordionOpen == 10 ? setAccordionOpen(["99"]) : setAccordionOpen(["10"]) }}>כתיבת מסר שיווקי מרכזי</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>המסר בנוי מהסבר קצר וממצה שעונה על שלוש שאלות מרכזיות:</div>
                                        <div>למה כדאי לקהל היעד הספציפי לרכוש את המוצר/שירות אותו אני מציע?</div>
                                        <div>למה דווקא ממני?</div>
                                        <div>איך הלקוח יקבל את השירות/מוצר?</div>
                                    </Col>
                                </Row>
                                <button onClick={() => setModal_1(true)} className='button_question_2'><BsQuestionCircle /> דוגמאות לכתיבת מסר שיווקי</button>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>המסר השיווקי שלכם</div></Form.Label>
                                                <Form.Control as="textarea" rows={6} className='form_control_textarea' size='sm' defaultValue={user_details.marketing_message}
                                                    onChange={(e) => setMarketing_message(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["11"]); submit_marketing_message() }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="11">
                        <Accordion.Header onClick={() => { AccordionOpen == 11 ? setAccordionOpen(["99"]) : setAccordionOpen(["11"]) }}>בניית משפך שיווקי אפקטיבי</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>תהליך שיווקי שמכניס מצד אחד את מירב המתעניינים במוצר/שירות שלכם, ומוציא מהצד השני את הלקוחות ששילמו.</div>
                                        <div>המשפך בנוי בצורה הבאה:</div>
                                        <div className='margin_10'>א. מתן ערך מקצועי חינמי לקהל היעד הרלוונטי באמצעות הזמנה להשארת פרטים ורישום.</div>
                                        <div>ב. ייזום ונקיטת מהלך שיווקי ממוקד מצדנו למשאירי הפרטים</div>
                                        <div>ג. הובלה לסגירת עסקה</div>
                                        <div className='booklet'>חוברת עבודה:</div>
                                        <div>כאן תוכלו להוריד את חוברת העבוד לבניית המשפך השיווקי לעסק שלכם</div>
                                        <div className='div_button_booklet'>
                                            <a href="המשפך_השיווקי_-_חוברת_עבודה.pdf" download="המשפך השיווקי - חוברת עבודה" className='button_booklet'>להורדה של חוברת העבודה <BiSolidDownload /></a>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='margin_10'><button onClick={() => setModal_2(true)} className='button_question_logo_1'>
                                    <BsQuestionCircle /> דוגמאות למשפך שיווקי</button></div>
                                <div><button onClick={() => setModal_3(true)} className='button_question_logo_2'>
                                    <BsQuestionCircle /> כיצד נבנה ונפעיל משפך וקמפיין שיווקי ברשת</button></div>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>תיאור המשפך ואופן פעולתו</div></Form.Label>
                                                <Form.Control as="textarea" rows={6} className='form_control_textarea' size='sm'
                                                    defaultValue={user_details.marketing_funnel}
                                                    onChange={(e) => setMarketing_funnel(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["12"]); submit_marketing_funnel() }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="12">
                        <Accordion.Header onClick={() => { AccordionOpen == 12 ? setAccordionOpen(["99"]) : setAccordionOpen(["12"]) }}>הקמת תכנית שיווקית בפועל</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>בחלק האחרון של התהליך, נלמד לייצר תכנית שיווק פשוטה וברורה.</div>
                                        <div>לכמה מתעניינים בשרות שלכם עליכם להגיע ומאיפה הם יגיעו</div>
                                        <div>תוכלו לתכנן את הפעולות הדרושות על מנת לשווק את העסק בשלבים.</div>
                                        <div>נמצא את מספר הפעולות שעלינו לבצע בכל אחד מהשלבים.</div>
                                        <div className='margin_10'>א. מספר יחידות המוצר/שירות אותן תכננתי למכור</div>
                                        <div>ב. הערכת מספר שיחות המכירה שעליי לבצע על מנת להשיג את מספר המכירות המבוקש.</div>
                                    </Col>
                                </Row>
                                <button onClick={() => setModal_4(true)} className='button_question_2'><BsQuestionCircle /> דוגמאות לתכנית שיווקית בפועל</button>
                                <Row>
                                    <Col sm={7} className='no_padding'>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label><div className='aco_body_2'>התכנית השיווקית שלך</div></Form.Label>
                                                <Form.Control as="textarea" rows={6} className='form_control_textarea' size='sm'
                                                    defaultValue={user_details.marketing_plan}
                                                    onChange={(e) => setmarketing_plan(e.target.value)} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { setAccordionOpen(["13"]); submit_marketing_plan(); }}>
                                        שמור והמשך לשלב הבא
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="13" className='aco_bottom'>
                        <Accordion.Header onClick={() => { AccordionOpen == 13 ? setAccordionOpen(["99"]) : setAccordionOpen(["13"]) }}>שיחת המכירה</Accordion.Header>
                        <Accordion.Body>
                            <div className='aco_body'>
                                <Row>
                                    <Col sm={8} className='no_padding'>
                                        <div>בהמשך לשלב הקודם, כעת ניצור את שיחת המכירה שלכם.</div>
                                        <div>בשיחת המכירה, נדגיש כבר בפתיחה את הערך שאנו מציעים ללקוח.</div>
                                        <div>זהו ערך שבעבורו כדאי לו לרכוש את המוצר או השירות שאנו משווקים בכלל, ומאיתנו – בפרט.</div>
                                        <div>שיחת המכירה שתלמדו מסודרת בשיטת "צומת T" כך שלכל אורך המסר, הלקוח מתבקש או מוזמן לבחור בנתיב בדרכו לסגור עסקה.</div>
                                        <div>בסיום תסריט השיחה יופיע "משפט הסגירה" בו ניתן ללקוח שתי אופציות לבחירה ביניהן.</div>
                                        <div>לאחר שהוצגו האופציות, מופיע שלב "השתיקה", בו אנו ממתינים בסבלנות לתשובתו של הלקוח, ללא התערבות.</div>
                                    </Col>
                                </Row>
                                <button onClick={() => setModal_5(true)} className='button_question_2'><BsQuestionCircle /> דוגמאות לשיחת המכירה</button>
                                <Row>
                                    <Col sm={6} className='no_padding'>
                                        <div>
                                            <Form>
                                                <Form.Group className="mb-3">
                                                    <Form.Label><div className='aco_body_2'>שיחת המכירה שלכם</div></Form.Label>
                                                    <Form.Control as="textarea" rows={6} className='form_control_textarea' size='sm'
                                                        defaultValue={user_details.sales_call}
                                                        onChange={(e) => setSales_call(e.target.value)} />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='div_button_save'>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={6}><div className='d-grid'><button className='button_save'
                                        onClick={() => { navigate("/dashboard"); setAccordionOpen(["14"]); submit_sales_call(); }}>
                                        שמור ועבור לתיק העסק
                                    </button></div></Col>
                                    <Col sm={3}></Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <Footer />

            <Modal show={modal_1} onHide={modal_1_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>דוגמאות לכתיבת מסר שיווקי</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <div className='modal_bolt_1'>  אבי לוי סוכן ביטוח:</div>

                            <div>  למה לרכוש ביטוח? על מנת לייצר שקט נפשי וביטחון כלכלי למשפחתי, עקב אירועים שונים שיפגעו ביכולתי לפרנס אותה.</div>
                            <div>  למה לרכוש ממני? סוכן ביטוח טוב נמדד בנחישות ובמקצועיות. אני לעולם לא מוותר ולא הולך לישון לפני שהשגתי את סכום הביטוח עבור לקוחותיי.</div>
                            <div>   אגיע אליכם תוך 48 שעות בכל קריאה.</div>
                            <div></div>
                            <div> איך השירות מתבצע?</div>
                            <div>• אגיע לביתכם ואבצע סקר מקיף של צרכי הביטוח השונים</div>
                            <div>• אסקור פוליסות ביטוח קיימות</div>
                            <div>• באמצעות מערכת מחשוב מתקדמת – אכין דו"ח המלצות שישקף את החוסרים הביטוחיים ויחסוך כפילויות בתשלום.</div>
                            <div>• לאחר אישורכם, אבצע את מסקנות הדו"ח.</div>
                            <div>• אחת לחצי שנה נבצע פגישת עדכונים</div>

                            <div className='modal_bolt_2'>   "הבגט הלוהט":</div>

                            <div>למה בגט? מתאים לכל שעות היום וניתן לבחור על פי טעם אישי.</div>
                            <div>למה ממני? במהלך 5 השנים האחרונות התמחיתי באפיית בגטים בתנור צרפתי מיוחד שהופך את הסנדוויץ' למעדן אמיתי.</div>

                            <div>איך יתבצע השירות?</div>
                            <div>• אעביר אליכם תפריט ראשי להתרשמות</div>
                            <div>• בכל יום א, עד השעה 12:00 אשלח אליכם מסרון עם תפריט שבועי מיוחד.</div>
                            <div>• החלוקה מתבצעת כל יום בשתי פעימות: מ-8 עד השעה 12:00 ומ-16:00 עד 18:00.</div>
                            <div>• זמן החלוקה – עד שלושים דקות</div>
                        </Col>
                        <Col sm={6}>
                            <div className='modal_bolt_3'> "מטריקס" – הוראת מתמטיקה מתקדמת</div>
                            <div>למה לרכוש לימוד מתמטיקה אישי? שיטת הלימוד בבתי הספר אינה מאפשרת מיצוי יכולות אישיות ויוצרת תסכול ואכזבה.</div>
                            <div>למה לקנות ממני?</div>
                            <div>שיטת המטריקס היא שיטה ייחודית שפיתחתי ומבוססת על ארבעת עקרונות למידה שונים. השיטה מייצרת רמת הפנמה ייחודית ויוצאת מגדר הרגיל.</div>
                            <div>איך מתבצע השירות?</div>
                            <div>• אשלח שאלון כדי לבדוק את רמת השפה הנוכחית שלכם.</div>
                            <div>• נבצע פגישה שבועית בת שעה, פרונטלית או מקוונת.</div>
                            <div>• פעמיים בשבוע ננהל שיחת טלפון בת עשר דקות באנגלית סביב נושאים אקטואליים</div>

                            <div className='modal_bolt_2'>"סל-נאו – תוספת המחץ למערך המכירות שלך"</div>
                            <div>למה להשתמש באיש מכירות חיצוני? לרוב העסקים קשה להגדיל את מספר המוכרים עקב הגדלת ההוצאות הקבועות. אנחנו מרוויחים את רוב הכנסתנו רק לאחר המכירה בפועל.</div>
                            <div>למה להשתמש בי? 15 שנות ניסיון בשישה ענפים שונים מאפשרות לי ליצור פריצות דרך בתוצאות יוצאות מגדר הרגיל.</div>
                            <div>איך מתבצע השירות?</div>
                            <div>• אבקר בבית העסק כדי ללמוד ולהבין את כל מהלך המכירה.</div>
                            <div>• אגיש דו"ח הערות והמלצות.</div>
                            <div>• נסכם על תסריטי שיחה מדוייקים.</div>
                            <div>• בכל בוקר אקבל מכם לידים בדו"ח מסודר.</div>
                            <div>• בכל ערב אגיש דו"ח תוצאות יומי – מה הולידו הפניות שלי ללידים שהגשתם?.</div>
                            <div>• אחת לשבועיים נבצע פגישת למידה ושיפור</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_2} onHide={modal_2_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>דוגמאות למשפך שיווקי</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <div className='modal_bolt_1'>1. אני מורה פרטי לאנגלית:</div>

                            <div>• אצור מדריך הכולל את ״5 כללי הזהב ללימוד והטמעת שפה חדשה״</div>
                            <div>• אשלח אותו לכל קבוצות הווטסאפ הרלבנטיות, כגון הורי תלמידים בכיתת ביתי ובנוסף, אעלה כפוסט בפייסבוק ובאינסטגרם</div>
                            <div>• כדי לקבלו, מתעניינים ישאירו פרטי התקשרות</div>
                            <div>• לאחר 48 שעות אתקשר לכל מי שהשאיר הפרטים ואבקש משוב</div>
                            <div>• עם התפתחות השיחה, אציע לכל משתתף סדרה של 5 שיעורים פרטיים איתי</div>

                            <div className='modal_bolt_2'>2. אני יועץ עסקי עצמאי:</div>

                            <div>• אכין סדרה של שלושה סרטונים בני 7 דקות כ״א, בהם אפרט את הטעויות הנפוצות המובילות לכישלון עסקי וכיצד להימנע מהן,</div>
                            <div>• אפיץ את הסרטונים ברשתות ואף אשלח אותם בפייסבוק לבעלי עסקים בהודעה פרטית בצ׳אט</div>
                            <div>• כל מי שהביע עניין בסרטונים, אזמין אותו לשיחת תובנות והטמעת הנלמד בפגישה מקוונת אחד על אחד בזום</div>
                            <div>• בסיום כל שיחה אציע חודש ליווי במחיר מיוחד</div>
                        </Col>
                        <Col sm={6}>
                            <div className='modal_bolt_3'>3. אני אופה עוגות ימי הולדת:</div>

                            <div>• אצלם 4-5 עוגות מיוחדות שאפיתי ואצרף לתמונות מתכון לעוגת קצפת מיוחדת.</div>
                            <div>• בנוסף, אגיע ביום שישי לשערי בית הספר היסודי בו לומד בני ואחלק פלאייר עם תמונות</div>
                            <div>• אפנה לכל המתעניינים ואאסוף תאריכי יום ההולדת של כל בני המשפחה המורחבת</div>
                            <div>• חודש לפני כל תאריך, אשלח דף נחיתה עם תמונות העוגות המיוחדות שאני אופה ואציע להתכונן מראש ולהזמין ממני</div>

                            <div className='modal_bolt_2'>4. אני איש מכירות עצמאי:</div>

                            <div>• אכין מדריך להתמודדות עם התנגדויות לקוח</div>
                            <div>• אעלה המדריך ברשתות החברתיות,</div>
                            <div>• אשלח המדריך לבעלי חברות הקשורים לחברי ובני משפחתי,</div>
                            <div>• אאתר חברות מסוימות שאני מזהה כי ביכולתי למכור את מוצריהן ואשלח להן המדריך</div>
                            <div>• אפנה לגל המתעניינים עם הצעה לפיילוט שבועי על בסיס הצלחות בלבד</div>
                            <div>• אקבע פגישה עם כל חברה בה התקיים הפיילוט להרחבת שיתוף הפעולה</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_3} onHide={modal_3_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>כיצד נבנה ונפעיל משפך וקמפיין שיווקי ברשת</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <iframe src="https://player.vimeo.com/video/422682940" width="100%"
                                data-ready="true" allowfullscreen allow="autoplay; fullscreen" className='frame_height'
                            ></iframe>
                        </Col>
                        <Col sm={6}>
                            <div>לנוחותך יצרנו סרטון שמראה שלב אחר שלב</div>
                            <div>איך ניתן איך ניתן לבנות ולהפעיל משפך וקמפיין שיווקי ברשת מבלי להתאמץ.</div>
                            <div>לחלופין, ניתן גם לפנות לגורם מקצועי שידע לסייע לך בזה.</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_4} onHide={modal_4_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>דוגמאות לתכנית שיווקית בפועל</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <div className='modal_bolt_1'>אני מדריך כושר עצמאי.</div>

                            <div>• על פי התוכנית העסקית עליי לגייס 7 לקוחות.</div>
                            <div>• הערכתי היא שעל מנת לגייס לקוח אחד עלי לנהל שלוש שיחות מכירה.</div>
                            <div>• כדי להגיע לשיחת מכירה אחת עלי להגיע לחמישה מתעניינים. לפיכך, עליי לייצר 105 מתעניינים בשירותיי
                                (7 לקוחות * 3 שיחות מכירה * 5 מתעניינים)</div>

                            <div className='modal_bolt_3'>פירוט מתעניינים על פי מקור הגעה:</div>

                            <div>• 30 מתעניינים יגיעו לאחר שהקליקו על מדריך כושר בייתי שהפצתי בחינם בפייסבוק ובאינסטגרם</div>
                            <div>• 45 מתעניינים יגיעו עקב הפניות שאספתי כתוצאה משיחות עם משפחתי וחבריי.</div>
                            <div>• 20 מתעניינים יגיעו מפוסטים ובהם תוכן מקצועי,
                                שפרסמתי בקבוצות רלוונטיות בפייסבוק, לתחום עיסוקי ולמיקומי הגיאוגרפי.</div>
                            <div>• 15 מתעניינים יגיעו כתוצאה מהודעות אישיות ששלחתי במסנג'ר לחבריי ברשתות.</div>
                        </Col>
                        <Col sm={6}>
                            <div className='modal_bolt_3'>אני איש שיווק עצמאי המסייע לעסקים קטנים להגדיל את חשיפתם ברשתות החברתיות ובכלל.</div>

                            <div>• על פי התוכנית העסקית עליי לגייס 3 לקוחות עסקיים. הערכתי היא שעל מנת לגייס לקוח אחד, עלי לנהל 4 פגישות מכירה.</div>
                            <div>• כדי ליצור פגישת מכירה אחת, עלי להגיע ל-3 בעלי עסקים מתעניינים. לפיכך, עליי לייצר 36 מתעניינים עסקיים בשירותיי </div>
                            (3 לקוחות * 4 שיחות מכירה * 3 מתעניינים)

                            <div className='modal_bolt_3'>פירוט מתעניינים על פי מקור הגעה:</div>

                            <div>• 10 מתעניינים עסקיים יגיעו מסרטון שיווקי שהעליתי בפייסבוק ואינסטגרם.</div>
                            <div>• 7 מתעניינים עסקיים יגיעו עקב נט וורקינג שעשיתי בזמן כנס שהשתתפתי בו.</div>
                            <div>• 8 מתעניינים יגיעו עקב פוסטים שפרסמתי בקבוצות רלוונטיות לתחום עיסוקי.</div>
                            <div>• 11 מתעניינים יגיעו כתוצאה מהודעות אישיות ששלחתי במסנג'ר לחבריי ברשתות.</div>
                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={modal_5} onHide={modal_5_close} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>דוגמאות לשיחת המכירה</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <div className='modal_bolt_1'>1. מוכר ג'חנון עצמאי:</div>

                            <div>שלום _____, מדבר אבי מ"בוטיק הג'חנון", מה שלומך? שמחתי לקרוא את התגובה שלך בפייסבוק. אין כמו ג'חנון מלאכת יד עם ביצה טרייה בשבת בבוקר.
                                אצלנו אתה מקבל רסק עגבניות אורגני שאנו מגדלים בעצמנו ללא חומרי ריסוס והדברה.</div>
                            <div>האם תעדיף להזמין לשבת הקרובה בלבד או להזמין לחודש מראש ולהנות מ-10 אחוזי הנחה ומשלוח חינם?</div>

                            <div className='modal_bolt_2'>2. איש שיווק עצמאי:</div>

                            <div>שלום____, מדברת רונית מ"שיווק יעיל". שמחתי לראות שנכנסת לקישור שמציג את המדריך שלי, "10 כללי שיווק ברשת". עסקים מצליחים יוצרים שינוי ונוקטים בדרכי פעולה חדשות אחת לתקופה וטוב שאתה עושה כך.</div>
                            <div>האם קראת והתעמקת במדריך?</div>
                            <div>אם כן – איזה יופי! מה הדבר המרכזי שהפקת? (שיחה קצרה בנושא)</div>
                            <div>במסגרת השירות שלי, אני מעניקה לכל בעל עסק שנחשף למדריך, פגישת ניתוח שיווקית, בת 45 דקות ללא עלות.</div>
                            <div>חשוב להדגיש, פגישה שכזו קונים ממני בשוטף ב-650 ₪ פלוס מע"מ. החלטתי לעשות כן כחלק ממהלך שיווקי מקיף להגדלת צבר הלקוחות שלי. איפה תעדיף להיפגש, במשרדך או במשרדי אשר נמצא במרכז העיר?</div>
                            <div>מעולה, אז ניפגש אצלך, בוא נפתח יומנים ונתאם.</div>
                            <div>אם לא – חבל, אבל מובן... קשה להרים את הראש מעבר לשוטף גם כשמדובר בהתפתחות עסקית. בשל כך וכחלק ממהלך אסטרטגי שהוצאתי לפועל על מנת להגדיל את צבר הלקוחות שלי, ארצה לפגוש אותך. בפגישה נטמיע את העקרונות החשובים מתוך המדריך.</div>
                            <div>חשוב להדגיש שעלות הפגישה בשוטף היא 650 ₪ לפני מע"מ ובחודש הקרוב היא ניתנת בחינם. איפה תעדיף להיפגש, במשרדך או במשרדי אשר נמצא במרכז העיר?</div>
                            <div>מעולה, אז ניפגש אצלך, בוא נפתח יומנים ונתאם.</div>
                        </Col>
                        <Col sm={6}>
                            <div className='modal_bolt_3'>3. מורה לגיטרה:</div>

                            <div>הי ___, מדבר יובל המורה לגיטרה.</div>
                            <div>קבלתי אם הטלפון שלך מירדן בת דודתך, שבנה למד אצלי גיטרה בשיטה הייחודית בתוך 3 חודשים. הבנתי ממנה שיתכן שבנך מעוניין ללמוד גם הוא.
                                האם תעדיפו שהשיעורים יתקיימו בביתכם, אצלי בסטודיו או בצורה מקוונת באמצעות "זום"?</div>
                            <div>א. הלקוח בחר מיקום – הסבר כיצד השירות ניתן ומה המחיר ועבור למשפט הסגירה: האם תעדיפו לשלם לפני כל שיעור, או אחת לחודש בחשבונית מרוכזת?</div>
                            <div>ב. הלקוח ביקש לברר מספר פרטים נוספים לפני שיחליט על מיקום - תענה לו בקצרה וחזור למשפט הסגירה.</div>

                            <div className='div_link_marketing'><LuStars className='stars' /> צריכים עזרה נוספת?
                                <span><Link className='link_marketing' to="/contact_us">
                                    קבלו ייעוץ ממאמן עסקי ללא עלות!</Link></span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </div >);
}

export default MarketingBusiness;