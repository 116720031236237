import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import './App.css';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Wellcome from './components/wellcome';
import Signup from './components/signup';
import Login from './components/login';
import ResetPassword from './components/password';
import UserDetails from './components/user_details';
import BusinessDetails from './components/business_details';
import BusinessProducts from './components/business_products';
import PropertyMarketings from './components/property_marketings';
import MarketingBusiness from './components/marketing_business';
import Dashboard from './components/dashboard';
import ContactUs from './components/contact_us';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { BsFillXCircleFill } from 'react-icons/bs';
import { Col, Row, Modal, Spinner } from 'react-bootstrap';
import { GiWhistle } from 'react-icons/gi';
import { AiOutlineSetting } from 'react-icons/ai';
import { HiOutlinePrinter } from 'react-icons/hi';
import { LiaUserEditSolid } from 'react-icons/lia';
import { RxExit } from 'react-icons/rx'

function App() {
  const [user_details, setUser_details] = useState({ general_definition: "", business_name: "", slogan: "" });
  const [user_products, setUser_products] = useState([]);
  const [user_products_target, setUser_products_target] = useState([]);
  const [user_products_no_target, setUser_products_no_target] = useState([]);
  const [refresh_api, setRefresh_api] = useState(0);
  const [AccordionOpen, setAccordionOpen] = useState(["0"]);
  const [onboarding, setOnboarding] = useState(false);

  const [show_menu, setShow_menu] = useState(false);

  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(window.matchMedia('(max-width: 800px)').matches);

  const [modal_spinner, setModal_spinner] = useState(false);

  const navigate = useNavigate();

  //לא מציג את התפריט לדף של הכניסה
  const showNavbar = window.location.href.includes("business_details") || window.location.href.includes("business_products")
    || window.location.href.includes("contact_us") || window.location.href.includes("dashboard")
    || window.location.href.includes("marketing_business") || window.location.href.includes("property_marketings")
    || window.location.href.includes("user_details");

  //מביא את פרטי העסק.
  useEffect(() => {
    if (showNavbar) {
      axios.post('/user', {
      }, { withCredentials: true })
        .then(function (response) {
          setUser_details(response.data[0])
        })
        .catch(function (error) {
          if (error.response.status == 401) { navigate("/login") }
          console.log(error);
        })

      axios.post('/get_products', {
      }, { withCredentials: true })
        .then(function (response) {
          setUser_products(response.data)
        })
        .catch(function (error) {
          if (error.response.status == 401) { navigate("/login") }
          console.log(error);
        })
    }
  }, [refresh_api]);

  return (
    <div className="App">
      <div style={{ display: 'flex', height: '100%', width: '100%' }}>
        {showNavbar ? <div>
          <Sidebar backgroundColor={"#000000"} onBackdropClick={() => setToggled(false)}
            toggled={toggled} customBreakPoint="800px" onBreakPoint={setBroken} rtl width="260px">
            <div>
              <Link to="/dashboard" onClick={() => setToggled(false)}><div className='div_logo_nav'><img src='multiu_logo.png' className='logo_nav' /></div></Link>
              <hr className='sidebar_hr'></hr>
            </div>
            <Link to="/dashboard" className='sidebar_link' onClick={() => setToggled(false)}><Row className='no_padding'>
              <Col xs={2}><div className='logo_nav_2'><img src='logo_u.png' width={22} /></div></Col>
              <Col xs={10}><div className='sidebar_title'>תיק העסק</div></Col>
            </Row></Link>
            <hr className='sidebar_hr'></hr>
            <Menu>
              <div className='sidebar_title_2'><button onClick={() => { navigate("/business_details"); setAccordionOpen(["99"]); setToggled(false); setShow_menu(false); }}
                className='sidebar_title_button'>פרטי העסק</button></div>
              <hr className='sidebar_hr_2'></hr>
              <MenuItem className='menu_item' component={<Link to="/business_details" />}
                icon={!user_details.general_definition ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["0"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.general_definition ? { color: '#9c8d8d' } : null}>תחום והגדרה כללית</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/business_details" />}
                icon={!user_details.business_name ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["1"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.business_name ? { color: '#9c8d8d' } : null}>שם העסק</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/business_details" />}
                icon={!user_details.business_logo ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["2"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.business_logo ? { color: '#9c8d8d' } : null}>לוגו העסק</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/business_details" />}
                icon={!user_details.slogan ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["3"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.slogan ? { color: '#9c8d8d' } : null}>סלוגן</MenuItem>

              <div className='sidebar_title_2'><button onClick={() => { navigate("/business_products"); setAccordionOpen(["99"]); setToggled(false); setShow_menu(false); }}
                className='sidebar_title_button'>סל מוצרים ויעדים חודשיים</button></div>
              <hr className='sidebar_hr_2'></hr>
              <MenuItem className='menu_item' component={<Link to="/business_products" />}
                icon={!user_details.profit ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["4"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.profit ? { color: '#9c8d8d' } : null}>רווח מתוכנן</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/business_products" />}
                icon={user_products.length == 0 ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["5"]); setToggled(false); setShow_menu(false); }}
                style={user_products.length == 0 ? { color: '#9c8d8d' } : null}>המוצרים</MenuItem>

              <div className='sidebar_title_2'><button onClick={() => { navigate("/property_marketings"); setAccordionOpen(["99"]); setToggled(false); setShow_menu(false); }}
                className='sidebar_title_button'>נכסים אינטרנטיים</button></div>
              <hr className='sidebar_hr_2'></hr>
              <MenuItem className='menu_item' component={<Link to="/property_marketings" />}
                icon={!user_details.facebook ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["6"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.facebook ? { color: '#9c8d8d' } : null}>דף פייסבוק</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/property_marketings" />}
                icon={!user_details.instagram ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["7"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.instagram ? { color: '#9c8d8d' } : null}>פרופיל אינסטגרם</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/property_marketings" />}
                icon={!user_details.minisite ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["8"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.minisite ? { color: '#9c8d8d' } : null}>מיני סייט</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/property_marketings" />}
                icon={!user_details.youtube ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["9"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.youtube ? { color: '#9c8d8d' } : null}>סרטון תדמית</MenuItem>

              <div className='sidebar_title_2'><button onClick={() => { navigate("/marketing_business"); setAccordionOpen(["99"]); setToggled(false); setShow_menu(false); }}
                className='sidebar_title_button'>שיווק העסק</button></div>
              <hr className='sidebar_hr_2'></hr>
              <MenuItem className='menu_item' component={<Link to="/marketing_business" />}
                icon={!user_details.marketing_message ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["10"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.marketing_message ? { color: '#9c8d8d' } : null}>מסר שיווקי</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/marketing_business" />}
                icon={!user_details.marketing_funnel ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["11"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.marketing_funnel ? { color: '#9c8d8d' } : null}>משפך שיווקי</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/marketing_business" />}
                icon={!user_details.marketing_plan ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["12"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.marketing_plan ? { color: '#9c8d8d' } : null}>תכנית שיווקית בפועל</MenuItem>
              <MenuItem className='menu_item' component={<Link to="/marketing_business" />}
                icon={!user_details.sales_call ? <BsFillXCircleFill /> : <BsFillCheckCircleFill />} onClick={() => { setAccordionOpen(["13"]); setToggled(false); setShow_menu(false); }}
                style={!user_details.sales_call ? { color: '#9c8d8d' } : null}>שיחת המכירה</MenuItem>
            </Menu>
            <hr className='sidebar_hr_3'></hr>
            <div className='link_nav_3'>
              <Link to="/contact_us" className='sidebar_link' onClick={() => { setToggled(false); setShow_menu(false); }}>
                <span className='logo_nav_3'><GiWhistle /></span>
                <span className='sidebar_title_3'>ייעוץ ממאמן עסקי ללא עלות</span>
              </Link>
            </div>
          </Sidebar>
        </div> : null}

        <div className='ba_body'>
          {showNavbar && window.innerWidth < 800 ?
            <div className='dashboard_title_head'>
              <Row>
                <Col xs={2}>
                  <div className='div_button_nav_2'>
                    <button className='button_nav_2' onClick={() => setToggled(!toggled)}>
                      <img src='hamburger.png' width={25} /></button>
                  </div>
                </Col>
                <Col xs={8}>
                  <Link to="/dashboard"><div className='logo_nav_4'><img src='multiu_logo.png' width={150} /></div></Link>
                </Col>

                <Col xs={2}>
                  <div className='div_button_nav_2'>
                    <button className='button_nav_2' onClick={() => show_menu ? setShow_menu(false) : setShow_menu(true)}>
                      <img src='kebab.png' width={5} /></button>
                  </div>
                </Col>
              </Row>
            </div> : null}


          <Routes>
            <Route path="/" element={<Wellcome />} />
            <Route path="/dashboard" element={<Dashboard show_menu={show_menu} setShow_menu={setShow_menu}
              user_details={user_details} setAccordionOpen={setAccordionOpen} setModal_spinner={setModal_spinner}
              user_products={user_products}
              refresh_api={refresh_api} setRefresh_api={setRefresh_api} onboarding={onboarding} setOnboarding={setOnboarding} />} />
            <Route path="login" element={<Login refresh_api={refresh_api} setRefresh_api={setRefresh_api} />} />
            <Route path="signup/:coach" element={<Signup refresh_api={refresh_api} setRefresh_api={setRefresh_api} setOnboarding={setOnboarding} />} />
            <Route path="password" element={<ResetPassword />} />
            <Route path="user_details" element={<UserDetails user_details={user_details}
              refresh_api={refresh_api} setRefresh_api={setRefresh_api} setModal_spinner={setModal_spinner} />} />
            <Route path="contact_us" element={<ContactUs broken={broken} toggled={toggled} setToggled={setToggled} />} />
            <Route path="business_details" element={<BusinessDetails setModal_spinner={setModal_spinner}
              AccordionOpen={AccordionOpen} setAccordionOpen={setAccordionOpen}
              user_details={user_details} refresh_api={refresh_api} setRefresh_api={setRefresh_api} />} />
            <Route path="business_products" element={<BusinessProducts setModal_spinner={setModal_spinner}
              AccordionOpen={AccordionOpen} setAccordionOpen={setAccordionOpen} user_details={user_details}
              refresh_api={refresh_api} setRefresh_api={setRefresh_api} user_products={user_products} />} />
            <Route path="property_marketings" element={<PropertyMarketings AccordionOpen={AccordionOpen}
              setAccordionOpen={setAccordionOpen} user_details={user_details} refresh_api={refresh_api}
              setRefresh_api={setRefresh_api} user_products={user_products} setModal_spinner={setModal_spinner} />} />
            <Route path="marketing_business" element={<MarketingBusiness
              AccordionOpen={AccordionOpen} setAccordionOpen={setAccordionOpen} user_details={user_details}
              refresh_api={refresh_api} setRefresh_api={setRefresh_api} user_products={user_products} setModal_spinner={setModal_spinner} />} />
          </Routes>
          <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
            closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />

          {show_menu ? <div className='test1'>
            <div className='head_menu'>
              <div className='div_dashboard_logo_3 d-grid'>
                {user_details.business_logo ? <img src={user_details.business_logo} className='dashboard_logo_3'></img> :
                  <img src="./no_logo.png" className='dashboard_logo_3'></img>}
              </div>
              <div className='dashboard_business_name_2'>{user_details.business_name}</div>
              <div className='dashboard_slogan_2'>{user_details.slogan}</div>
            </div>
            <div className='body_menu'>
              <button className='button_menu' onClick={() => { navigate("/business_details"); setAccordionOpen(["0"]); setShow_menu(false) }}>
                <LiaUserEditSolid className='icon_menu' /> עריכת פרטי העסק</button>
              <button className='button_menu' onClick={() => { navigate("/user_details"); setShow_menu(false); }}>
                <AiOutlineSetting className='icon_menu' /> עריכת פרטי משתמש</button>
              <button className='button_menu' onClick={() => { window.print(); setShow_menu(false); }}>
                <HiOutlinePrinter className='icon_menu' /> הדפסת תיק העסק</button>
              <button className='button_menu_2' onClick={() => { navigate("/contact_us"); setAccordionOpen(["0"]); setShow_menu(false) }}>
                <GiWhistle className='icon_menu_2' /> ייעוץ ממאמן עסקי</button>
            </div>
            <div className='footer_menu'>
              <button className='button_menu' onClick={() => {
                setShow_menu(false);
                document.cookie = 'token = ""';
                navigate("/");
              }}><RxExit className='icon_menu' /> התנתקות מהמערכת</button>
            </div>
          </div> : <div></div>}
        </div>
      </div>

      <Modal show={modal_spinner} centered size="sm">
        <Modal.Body>
          <div className='div_spinner'> <Spinner animation="border" variant="warning" /></div>
        </Modal.Body>
      </Modal>

    </div >);
}

export default App;
