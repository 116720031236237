import React, { useRef } from 'react';
import '../App.css';
import Footer from './footer';
import { Col, Row } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";


function Wellcome() {

    const navigate = useNavigate();

    const testRef = useRef(null);
    const scrollToElement = () => testRef.current.scrollIntoView();

    return (
        <div className='Wellcome'>
            <div className='wellcome_2'>
                <Row>
                    <Col sm={6} className='no_padding'>
                        <div className='div_Wellcome_1'>
                            <div className='wellcome_text'>
                                <img src='./multi3.png' className='wellcome_logo'></img>
                                <div className='wellcome_text_1'>להקים עסק מועד להצלחה?</div>
                                <div className='wellcome_text_2'>זה פשוט מאי פעם!</div>
                                <div className='wellcome_text_3'>
                                    <div>הכירו את multi U - המערכת היחידה שתצטרכו בכדי</div>
                                    <div>להקים ולנהל עסק מצליח, ולא תשלמו עליה שקל!</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} className='no_padding'>
                        {window.innerWidth > 800 ?
                            <video className='wellcome_logo_2' autoPlay loop muted playsInline >
                                <source src="./video_2.webm" type="video/webm" />
                            </video>

                            : null}
                    </Col>
                </Row>

                {window.innerWidth > 800 ?
                    <div>
                        <div className='div_icons'>
                            <Row>
                                <Col sm={3} xs={6}>
                                    <div className='wellcome_icon'>
                                        <img src='./process.png' className='wellcome_icon_2'></img>
                                        <div>קבלו הדרכה שלבית וברורה</div>
                                        <div>על כל פרט בהקמת העסק.</div>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className='wellcome_icon'>
                                        <img src='./shopping.png' className='wellcome_icon_3'></img>
                                        <div>בנו את סל המוצרים באופן</div>
                                        <div>מבוסס מחושב ונכון.</div>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className='wellcome_icon'>
                                        <img src='./chart.png' className='wellcome_icon_2'></img>
                                        <div>חשבו ועקבו אחר היעדים</div>
                                        <div>החודשיים שלכם.</div>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className='wellcome_icon'>
                                        <img src='./whistle3.png' className='wellcome_icon_2'></img>
                                        <div className='color_y'>קבלו ייעוץ ללא עלות ממאמן</div>
                                        <div className='color_y'>עסקי מוסמך.</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm={4}></Col>
                                <Col sm={4}>
                                    <div className='d-grid div_button_wellcome'>
                                        <button type='button' className='button_wellcome' onClick={() => navigate('./signup/1')}>הרשמו עכשיו בחינם!</button>
                                    </div>
                                    <div className='div_wellcome_hr'>
                                        <Row>
                                            <Col sm={4}><hr className='wellcome_hr'></hr></Col>
                                            <Col sm={4}>
                                                <div className='wellcome_hr_text'>כבר רשומים?</div>
                                            </Col>
                                            <Col sm={4}><hr className='wellcome_hr'></hr></Col>
                                        </Row>
                                    </div>
                                    <div className='d-grid div_button_wellcome_2'>
                                        <button type='button' className='button_wellcome_2' onClick={() => navigate('./login')}>התחברות למערכת</button>
                                    </div>
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                        </div>
                    </div>
                    :
                    <div>
                        <div>
                            <Row>
                                <Col sm={4}></Col>
                                <Col sm={4}>
                                    <div className='d-grid div_button_wellcome'>
                                        <button type='button' className='button_wellcome' onClick={() => navigate('./signup/1')}>הרשמו עכשיו בחינם!</button>
                                    </div>
                                    <div className='div_wellcome_hr'>
                                        <Row>
                                            <Col xs={3} className='no_padding'><hr className='wellcome_hr'></hr></Col>
                                            <Col xs={6} className='no_padding'>
                                                <div className='wellcome_hr_text'>כבר רשומים?</div>
                                            </Col>
                                            <Col xs={3} className='no_padding'><hr className='wellcome_hr'></hr></Col>
                                        </Row>
                                    </div>
                                    <div className='d-grid div_button_wellcome_2'>
                                        <button type='button' className='button_wellcome_2' onClick={() => navigate('./login')}>התחברות למערכת</button>
                                    </div>
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                        </div>
                        <video className='wellcome_logo_2' autoplay loop muted playsinline autoPlay >
                            <source src="./video_2.webm" type="video/webm" />
                        </video>
                        <div className='div_icons'>
                            <Row>
                                <Col sm={3} xs={6}>
                                    <div className='wellcome_icon'>
                                        <img src='./process.png' className='wellcome_icon_2'></img>
                                        <div>קבלו הדרכה שלבית וברורה על כל פרט בהקמת העסק.</div>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className='wellcome_icon'>
                                        <img src='./shopping.png' className='wellcome_icon_3'></img>
                                        <div>בנו את סל המוצרים באופן מבוסס מחושב ונכון.</div>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className='wellcome_icon'>
                                        <img src='./chart.png' className='wellcome_icon_2 icon_margin'></img>
                                        <div>חשבו ועקבו אחר היעדים החודשיים שלכם.</div>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className='wellcome_icon'>
                                        <img src='./whistle3.png' className='wellcome_icon_2 icon_margin'></img>
                                        <div className='color_y'>קבלו ייעוץ ללא עלות ממאמן עסקי מוסמך.</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                <Footer />

                <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                    closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            </div>
        </div >
    );
}

export default Wellcome;